import { Component, Input } from '@angular/core';
import {
  ContentGroup,
  ContentLibraryRuleConfiguration,
  ContentTextRuleConfiguration,
  DocumentContent,
  DocumentService,
  DocumentVersion,
  MoonDeskDocument,
  RuleConfiguration,
  RuleIDs,
  TasksService,
  TextsFormattingService,
  TranslationService,
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { IllustratorService } from '../../../services/illustrator.service';
import { SaveDocumentService } from '../../work/save-document/save-document.service';
import { RuleHelperService } from '../../../services/rule-helper.service';
import { RulesExecutionService } from '../../../services/rules/rules-execution.service';
import { DocumentContentsService } from '../../../services/document-contents.service';
import { FeedbackService } from 'src/app/services/feedback.service';

interface RuleGroup
{
  id: string;
  uiName: string;
  contentGroups: ContentGroup[];
  order?: number;

  isExpanded?: boolean;
}

@Component({
    selector: 'app-document-contents',
    templateUrl: './document-contents.component.html',
    styleUrls: ['./document-contents.component.scss'],
    standalone: false
})

export class DocumentContentsComponent {


  contentConfigurationReadOnly: Object =
  {
    'toolbar': false,
    'spellcheck': false,
    'askBeforePasteHTML': false,
    'askBeforePasteFromWord': false,
    'readonly': true,
    'showCharsCounter': false,
    'showWordsCounter': false,
    'showXPathInStatusbar': false,
  };

  rulesGroups: RuleGroup[] = [];
  @Input() private set contentGroups(groups: ContentGroup[])
  {
    this.rulesGroups = this.rulesGroups.filter(rg => rg.id !== 'Tasks');
    if (!groups || groups.length === 0)
    {
      return;
    }
    const contentsCount = groups.reduce((acc, cg) => acc + cg.documentContents.length, 0);
    const title = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.taskTitle',
      {contentsCount: contentsCount});
    const taskRuleGroup: RuleGroup =
    {
      id: 'Tasks',
      uiName: title,
      contentGroups: groups,
      order: 1
    };
    this.rulesGroups.push(taskRuleGroup);
    this.rulesGroups.sort((a, b) => a.order - b.order);
  }
  @Input() private set workspaceRules(rules: RuleConfiguration[])
  {
    this.rulesGroups = this.rulesGroups.filter(rg => rg.id !== 'Rules');
    if (!rules || rules.length === 0)
    {
      return;
    }
    const rulesContentGroups = this.mapRulesToDocContent(rules);
    const title = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.rulesTitle',
      {contentsCount: rulesContentGroups.length});
    this.rulesGroups.push(
    {
      id: 'Rules',
      uiName: title,
      contentGroups: rulesContentGroups,
      order: 2
    });
    this.rulesGroups.sort((a, b) => a.order - b.order);
  }
  @Input() currentDocument: MoonDeskDocument;


  constructor(private illService: IllustratorService,
              private feedbackService: FeedbackService,
              private saveDocService: SaveDocumentService,
              private rulesExecutionService: RulesExecutionService,
              private ruleHelperService: RuleHelperService,
              private tasksService: TasksService,
              private docService: DocumentService,
              private docContentsService: DocumentContentsService,
              private translationService: TranslationService,
              private textsFormattingService: TextsFormattingService
              )
  { }

  private mapRulesToDocContent(ruleConfigs: RuleConfiguration[]): ContentGroup[]
  {
    const contentGroups: ContentGroup[] = [];
    if (!ruleConfigs || ruleConfigs.length === 0)
    {
      return contentGroups;
    }
    const contentRulesConfig = ruleConfigs.filter(rule => rule.ruleID === RuleIDs.TEXT_CONTENT || rule.ruleID === RuleIDs.LIBRARY_CONTENT);
    for (const contentRuleConfig of contentRulesConfig)
    {
      if (contentRuleConfig.ruleID === RuleIDs.TEXT_CONTENT)
      {
        const mappedDocContents: DocumentContent[] = [];

        const config: ContentTextRuleConfiguration = JSON.parse(contentRuleConfig.ruleOptions);
        if (config.checkCondition === 'notExist')
        {
          continue;
        }
        const textContents = this.ruleHelperService.parseTextContents(config.richCheckText);
        for (let i = 0; i < textContents.length; i++)
        {
          const tc = textContents[i];
          const mappedDocContent: DocumentContent =
          {
            ruleConfiguration: contentRuleConfig,
            type: 'Text',
            content: tc.text,
            previousRuleCondition: i > 0 ? (tc.logicalConector === '||' ? 'Or' : 'And') : null,
            wildCard: tc.wildCard,
            description: this.textsFormattingService.removeHtml(contentRuleConfig.description),
            minSize: config.textSize,
            maxSize: config.maximumTextSize,
            caseSensitve: config.checkTextCaseSensitive,
            checkTextBasedOnMaxSize: config.checkTextBasedOnMaxSize
          };
          mappedDocContents.push(mappedDocContent);
        }

        const ruleContentGroup: ContentGroup =
        {
          name: config.ruleDescription,
          documentContents: mappedDocContents
        };

        contentGroups.push(ruleContentGroup);
      }
      else if (contentRuleConfig.ruleID === RuleIDs.LIBRARY_CONTENT)
      {
        const mappedDocContents: DocumentContent[] = [];
        const config: ContentLibraryRuleConfiguration = JSON.parse(contentRuleConfig.ruleOptions);
        for (let i = 0; i < config.docVersions.length; i++)
        {
          const libDocVersion = config.docVersions[i];
          const docVersion: DocumentVersion =
          {
            documentTags: [],
            fieldValues: [],
            fileType: '',
            metadata: null,
            id: libDocVersion.versionId,
            documentName: libDocVersion.docName,
          };
          this.docService.getSpecificFileUrl(libDocVersion.versionId, 'previewImage.png').then(url => docVersion.imageUrl = url);

          const mappedDocContent: DocumentContent =
          {
            ruleConfiguration: contentRuleConfig,
            type: 'LibraryElement',
            description: this.textsFormattingService.removeHtml(contentRuleConfig.description),
            documentVersionId: libDocVersion.versionId,
            documentVersion: docVersion,
            libContentLogicalOperatorSize: libDocVersion.logicalOperatorSize,
            libContentWidth: libDocVersion.width,
            libContentHeight: libDocVersion.height,
            previousRuleCondition: i > 0 ? (config.logicalOperator === 'or' ? 'Or' : 'And') : null
          };
          mappedDocContents.push(mappedDocContent);
        }

        const ruleContentGroup: ContentGroup =
        {
          name: config.ruleDescription,
          documentContents: mappedDocContents
        };

        contentGroups.push(ruleContentGroup);
      }
    }

    return contentGroups;
  }

  async linkTextContent(docContent: DocumentContent)
  {
    if (docContent.type !== 'Text')
    {
      console.log('NO TEXT CONTENT!');
      return;
    }
  }

  async linkContent(docContent: DocumentContent)
  {
    if (docContent.type === 'Text')
    {
      await this.docContentsService.addTextContent(docContent.content, docContent.minSize);
    }
    else if (docContent.type === 'LibraryElement')
    {
      await this.linkLibContent(docContent);
    }
  }

  private async linkLibContent(docContent: DocumentContent)
  {
    if (docContent.type !== 'LibraryElement')
    {
      console.log('NO LIBRARY CONTENT!');
    }
    if (docContent.busy)
    {
      return;
    }
    try
    {
      docContent.busy = true;
      await this.docContentsService.addLibContent(docContent.documentVersionId);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error placing content', err);
    }
    finally
    {
      docContent.busy = false;
    }
  }

  getImgSizeControlString(docContent: DocumentContent): string
  {
    if (docContent.type !== 'LibraryElement' || (docContent.libContentHeight === 0 && docContent.libContentWidth === 0))
    {
      return '';
    }

    let sizeLogicalOperator: string;
    switch (docContent.libContentLogicalOperatorSize)
    {
      case '<':
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.imgSmallerThan');
        break;
      case '>':
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.imgBiggerThan');
        break;
      default: // =
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.imgEqualTo');
        break;
    }

    const sizeConditions: string[] = [];
    if (docContent.libContentHeight > 0)
    {
      const heightTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.imgHeight',
        {size: docContent.libContentHeight});
      sizeConditions.push(heightTranslation);
    }
    if (docContent.libContentWidth > 0)
    {
      const widthTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.imgWidth',
        {size: docContent.libContentWidth});
      sizeConditions.push(widthTranslation);
    }

    const byTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.by');
    return `${sizeLogicalOperator} ${sizeConditions.join(` ${byTranslation} `)}`;
  }

  getWildcardTranslation(wildCard: 'contains' | 'startsWith' | 'endsWith' | 'matchesWith' | null): string
  {
    switch (wildCard)
    {
      case 'contains':
        return this.translationService.getTranslation('lid.ext.pages._shared.document-contents.contains');
      case 'startsWith':
        return this.translationService.getTranslation('lid.ext.pages._shared.document-contents.startsWith');
      case 'endsWith':
        return this.translationService.getTranslation('lid.ext.pages._shared.document-contents.endsWith');
      case 'matchesWith':
        return this.translationService.getTranslation('lid.ext.pages._shared.document-contents.matchesWith');
      default:
        return '';
    }
  }

  getTextSizeControlString(docContent: DocumentContent): string
  {
    if (docContent.type !== 'Text')
    {
      return '';
    }

    let sizeTranslation = '';
    if (docContent.minSize > 0)
    {
      sizeTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.textBiggerThan',
        { size: docContent.minSize }
      );
    }
    else if (docContent.maxSize > 0)
    {
      sizeTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.textSmallerThan',
        { size: docContent.maxSize }
      );
    }

    if (docContent.checkTextBasedOnMaxSize)
    {
      const uppercaseTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.uppercase');
      sizeTranslation += ` (${uppercaseTranslation})`;
    }
    else
    {
      const lowercaseTranslation = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.lowerCase');
      sizeTranslation += ` (${lowercaseTranslation})`;
    }

    return sizeTranslation;
  }

  async checkRule(docContent: DocumentContent)
  {
    if (docContent.busy)
    {
      return;
    }
    if (!this.currentDocument)
    {
      this.feedbackService.notifyMessage('Please open a document first');
      return;
    }
    if (!this.currentDocument.documentType)
    {
      this.feedbackService.notifyMessage('Please select a document type first');
      return;
    }
    try
    {
      docContent.busy = true;
      const docItems = await this.illService.getVisibleItems(this.currentDocument.workingCopy);
      const version = await this.saveDocService.createDocumentVersion(this.currentDocument, docItems, this.currentDocument.workingCopy);
      version.fullTextCharacters = await this.illService.getAllTextWithBoldReferences(this.currentDocument.workingCopy);
      const ruleConfiguration = docContent.ruleConfiguration ?? this.tasksService.getRulesFromContent([docContent], '')[0];
      const ruleResult = await this.rulesExecutionService.runRule(
        ruleConfiguration,
        this.currentDocument,
        version,
        this.currentDocument.workingCopy
      );
      await this.ruleHelperService.showResultsDialog([ruleResult], false, this.currentDocument, [ruleConfiguration]);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error running rule', err);
    }
    finally
    {
      docContent.busy = false;
    }
  }

}
