import { Component, Input } from '@angular/core';
import {
  DocumentVersionAIAnalysis
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/document/DocumentVersionAIAnalysis';
import {
  DocumentVersion,
  EventHubService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
    selector: 'app-document-ai-analysis',
    templateUrl: './document-ai-analysis.component.html',
    styleUrls: ['./document-ai-analysis.component.scss'],
    standalone: false
})
export class DocumentAIAnalysisComponent {

  aIAnalyses: DocumentVersionAIAnalysis[];
  aIGeneralAnalysis: DocumentVersionAIAnalysis;
  aIRulesAnalysis: DocumentVersionAIAnalysis;
  @Input() set documentVersion (dv: DocumentVersion)
  {
    if (dv?.aiAnalyses)
    {
      this.aIAnalyses = dv.aiAnalyses;
      this.aIGeneralAnalysis = dv.aiAnalyses.find(ai => ai.type === 'GeneralAIAnalysis');
      this.aIRulesAnalysis = dv.aiAnalyses.find(ai => ai.type === 'RulesAIAnalysis');
    }
  }

  busy: boolean;

  isGeneralExpanded: boolean = true;
  isRulesExpanded: boolean = true;

  constructor(private eventHubService: EventHubService) { }

}
