import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataIllustratorMap } from '../../../_models/smartmapping';
import { IllustratorService } from '../../../services/illustrator.service';
import * as _ from 'underscore';

@Component({
    selector: 'field-list',
    templateUrl: './field-list.component.html',
    styleUrls: ['./field-list.component.scss'],
    standalone: false
})
export class FieldListComponent implements OnInit
{
  @Input() busy: boolean;
  dataArray: DataIllustratorMap[];
  @Input() set data(maps: DataIllustratorMap[])
  {
    this.dataArray = _.sortBy(maps, m => m.fieldType.name.toLowerCase());
  }
  @Input() filepath: string;

  @Input() progress: number;
  @Input() status: string;

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() mapLinked: EventEmitter<DataIllustratorMap> = new EventEmitter<DataIllustratorMap>();

  constructor(private illService: IllustratorService) { }

  ngOnInit() {
  }

  getTooltipText(date: DataIllustratorMap): string {
    switch (date.status){
      case 'notassigned':
        return 'No element found';
      case 'ambiguous':
        return 'More than one element found';
      case 'wrongtype':
        return `Wrong type`;
      case 'wrongvalue':
        return 'Wrong value';
    }
    return undefined;
  }

  getValue(date: DataIllustratorMap): string
  {
    if (date.status == 'ok' && date.illustratorItems && date.illustratorItems.length > 0)
    {
      return date.illustratorItems[0].content;
    }
    return '';
  }

  async zoom(map: DataIllustratorMap)
  {
    if (this.busy) { return; }
    map.isSelected = true;
    await this.illService.selectItems(this.filepath, map.fieldType.code);
    await this.illService.bringSelectionIntoView(this.filepath);
  }

  async select(map: DataIllustratorMap)
  {
    if (this.busy || map.fieldType.type == 'Barcode') {
      return;
    }
    if (await this.illService.selectItems(this.filepath, map.fieldType.code))
    {
      _.forEach(this.dataArray, d => d.isSelected = d == map);
    }
  }

  async link(map: DataIllustratorMap)
  {
    if (this.busy) { return; }
    this.mapLinked.emit(map);
  }
}
