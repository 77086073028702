import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Progress } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
    selector: 'app-progress-control',
    templateUrl: './progress-control.component.html',
    styleUrls: ['./progress-control.component.scss'],
    standalone: false
})
export class ProgressControlComponent {

  // @Input() progress: Progress;
  progress: Progress;

  constructor(
    public dialogRef: MatDialogRef<ProgressControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Progress)
  {
    this.progress = data;
  }


  getProgressStatus(): string
  {
    if (this.progress.percentage !== 100)
    {
      let speed: string;
      let totalSize: string;
      let loadedSize: string;
      if (this.progress.totalMB > 1)
      {
        totalSize = this.progress.totalMB + ' MB';
        loadedSize = this.progress.loadedMB + ' MB';
      }
      else
      {
        totalSize = this.progress.totalKB + ' KB';
        loadedSize = this.progress.loadedKB + ' KB';
      }
      if (this.progress.speedMBps > 0.5)
      {
        speed = this.progress.speedMBps + ' MB/s';
      }
      else
      {
        speed = this.progress.speedKBps + ' KB/s';
      }
      return 'Uploading: ' + loadedSize + ' of ' + totalSize + ' at ' + speed;
    }
    else
    {
      return 'Please wait...';
    }
  }

  cancel()
  {
    this.progress.cancelAction();
    this.dialogRef.close();
  }

}
