import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-share-warning-dialog',
    templateUrl: './share-warning-dialog.component.html',
    styleUrls: ['./share-warning-dialog.component.scss'],
    standalone: false
})
export class ShareWarningDialogComponent
{

  message: string;
  list: string[];

  constructor(public dialogRef: MatDialogRef<ShareWarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.message = data.message;
    this.list = data.list;
  }

  close()
  {
    this.dialogRef.close();
  }
}
