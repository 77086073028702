import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { ConnectionService } from './services/connection.service';
import { DocumentService } from './services/document.service';
import { AuthService } from './services/auth.service';
import { OAuthService } from './services/oauth.service';
import { CompanyConfigurationService } from './services/company-configuration.service';
import { LoggingService } from './services/logging.service';
import { EventHubService } from './services/event-hub.service';
import { DynamsoftBarcodeService } from './services/dynamsoft-barcode.service';
import { NotificationHubService } from './services/notification-hub.service';
import { BackupRestoreService } from './services/backup-restore.service';
import { SupportService } from './services/support.service';
import { ExtensionService } from './services/extension.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SessionTimeoutService } from './services/session-timeout.service';
import { NgIdleModule } from '@ng-idle/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from './services/translation.service';
import { SubscriptionsService } from './services/subscriptions.service';
import localepl from '@angular/common/locales/pl';
registerLocaleData(localepl);
@NgModule({
  providers: [],
  imports: [
    NgIdleModule.forRoot()
  ]
})


export class MoonDeskLibServiceModule
{
  static forRoot(): ModuleWithProviders<any>
  {
    return {
      ngModule: MoonDeskLibServiceModule,
      providers: [
        ConnectionService,
        AuthService,
        OAuthService,
        CompanyConfigurationService,
        DocumentService,
        LoggingService,
        EventHubService,
        DynamsoftBarcodeService,
        NotificationHubService,
        BackupRestoreService,
        SupportService,
        ExtensionService,
        SessionTimeoutService,
        CookieService,
        TranslationService,
        SubscriptionsService,
        { provide: LOCALE_ID, useValue: 'pl-PL'},
      ],
    };
  }
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [],
  imports: [
      CommonModule
  ],
  providers: [
    provideHttpClient()
  ]
})
export class MoondeskWebLibModule
{
}
