import { Component, Input } from '@angular/core';
import {
  DocumentService,
  ExportFilesIdentifiers,
  MoonDeskDocument,
  NutritionalTable,
  NutritionalTableFact,
  TranslationService
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { IllustratorService } from 'src/app/services/illustrator.service';
import { FilepathService } from 'src/app/services/filepath.service';
import { FilesystemService } from 'src/app/services/filesystem.service';
import { FeedbackService } from 'src/app/services/feedback.service';

interface FactWithStatus extends NutritionalTableFact
{
  status: 'replaced' | 'not-found' | 'waiting';
}

@Component({
    selector: 'app-nutritional-table',
    templateUrl: './nutritional-table.component.html',
    styleUrls: ['./nutritional-table.component.scss'],
    standalone: false
})
export class NutritionalTableComponent {

  _nutritionalTable: NutritionalTable;
  @Input() set nutritionalTable(nt: NutritionalTable)
  {
    this._nutritionalTable = nt;
    if(nt && nt.documentId)
    {
      this.loadNutritionalDocument(nt.documentId);
    }
    else
    {
      this.nutritionalTableDocument = null;
    }

    if (nt.facts && nt.facts.length > 0)
    {
      this.nutritionalTableFacts = nt.facts.map(fact => ({...fact, status: 'waiting'}));
    }
    else
    {
      this.nutritionalTableFacts = [];
    }
  }
  @Input() currentDocumentFilePath: string;

  nutritionalTableDocument: MoonDeskDocument;
  nutritionalTableFacts: FactWithStatus[] = [];
  loadingDocument: boolean = false;
  downloadingDocument: boolean = false;

  constructor(private documentService: DocumentService,
    private feedbackService: FeedbackService,
    private translationService: TranslationService,
    private illService: IllustratorService,
    private filePath: FilepathService,
    private fileService: FilesystemService)
  {
  }

  get nutritionaTableTitle(): string
  {
    const valuesCount = this.nutritionalTableFacts?.length ?? 0;
    return this.translationService.getTranslation(
      'lid.ext.pages._shared.nutritional-table.nutritionalTable',
      {valuesCount: valuesCount}
    );
  }

  private async loadNutritionalDocument(documentId: string)
  {
    try
    {
      this.loadingDocument = true;
      this.nutritionalTableDocument = await this.documentService.getDocument(documentId);
    }
    catch(err)
    {
      this.feedbackService.notifyError(
        this.translationService.getTranslation('lid.ext.pages._shared.nutritional-table.errorLoadingDocument'),
        err.message);
    }
    finally
    {
      this.loadingDocument = false;
    }
  }

  async pasteNutritionalTableDocument()
  {
    if (!this.nutritionalTableDocument)
    {
      return;
    }
    try
    {
      this.downloadingDocument = true;
      const pdfUrl = await this.documentService.getFileUrl(
        this.nutritionalTableDocument.latestVersion,
        ExportFilesIdentifiers.PreviewPdf);

      const fileData = await this.documentService.downloadFileWithUrl(pdfUrl);
      const filePath = await this.filePath.getTempFilePath(this.nutritionalTableDocument.latestVersionId + '.pdf');

      await this.fileService.writeFileAsync(filePath, fileData, {encoding: 'base64'});

      this.illService.placeItem(filePath, true);

    }
    catch (err)
    {
      const errMsg = this.translationService.getTranslation('lid.ext.pages._shared.nutritional-table.errorPastingDocument');
      this.feedbackService.notifyError(errMsg, err);
    }
    finally
    {
      this.downloadingDocument = false;
    }
  }

  async findText(text: string)
  {
    await this.illService.unselectItems(this.currentDocumentFilePath);
    await this.illService.find(this.currentDocumentFilePath, text, false);
  }

  async pasteAllFactsValues()
  {
    if (!this.nutritionalTableFacts || this.nutritionalTableFacts.length === 0)
    {
      return;
    }
    await this.illService.unselectItems(this.currentDocumentFilePath);
    for (const fact of this.nutritionalTableFacts)
    {
      await this.pasteFactValue(fact);
    }
  }

  async pasteFactValue(fact: FactWithStatus)
  {
    if (!fact.key)
    {
      fact.status = 'not-found';
      return;
    }

    try
    {
      const count = await this.illService.findAndReplace(this.currentDocumentFilePath, fact.key, fact.value, false);
      fact.status = count > 0 ? 'replaced' : 'not-found';
    }
    catch (err)
    {
      const errMsg = this.translationService.getTranslation('lid.ext.pages._shared.nutritional-table.errorReplacingValue');
      this.feedbackService.notifyError(errMsg, err);
    }
  }
}
