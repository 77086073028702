import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'underscore';

import { ParsedPath } from '../../../_node/Node';
import { FilesystemService } from '../../../services/filesystem.service';
import { MatDialogRef, MAT_DIALOG_DATA,  MatDialog } from '@angular/material/dialog';
import { DocumentFilter, LoggingService,
        Severity, AuthService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { BulkService, SaveResult } from 'src/app/services/bulk.service';
import { InfoPopupComponent } from '../info-popup/info-popup.component';
import { IllustratorService } from 'src/app/services/illustrator.service';
import { FeedbackService } from 'src/app/services/feedback.service';

export class SelectedFile
{
  path: string;
  parsedPath: ParsedPath;
  result: SaveResult;
}

@Component({
    selector: 'bulk-import',
    templateUrl: './bulk-import.component.html',
    styleUrls: ['./bulk-import.component.scss'],
    standalone: false
})
export class BulkImportComponent implements OnInit
{
  bulkService: BulkService;

  importing: boolean;
  cancelled: boolean;
  status: string;
  progress: number;
  filters: DocumentFilter;
  filtersHasChanged: boolean;
  error: boolean = false;
  selectedFolder: string;

  checkRules: boolean = true;

  selection: SelectedFile[] = [];
  lastSelection: SelectedFile[] = [];
  current: SelectedFile;
  changedTag: string;
  checkPath: boolean;
  checkName: boolean;

  bulkImportPath: string;
  constructor(
    private authService: AuthService,
    private filesystem: FilesystemService,
    public dialogRef: MatDialogRef<BulkImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private feedbackService: FeedbackService,
    private logService: LoggingService,
    private dialog: MatDialog,
    private illustratorService: IllustratorService)
  {
    this.bulkService = data.service;
  }

  ngOnInit()
  {
    const id = this.authService.getCurrentIdentity();
    if (id.company)
    {
      const dts = _.filter(id.company.documentTypes, dt => dt.isLibraryType === false);
      if (dts.length === 1)
      {
        this.filters =
        {
          companyId: id.company.id,
          docTypeIds: [dts[0].id],
          page: -1,
          classSelections: [],
          skipPreviewUrls: true
        };
      }
    }
  }

  filtersChanged = async (filters: DocumentFilter) =>
  {
    this.filters = filters;
    this.filtersHasChanged = true;
    if (this.selection)
    {
      _.forEach(this.selection, file =>
        {
          if (file.result)
          {
            file.result.code = 0;
          }
        });
    }
  }

  filtersComplete(): boolean
  {
    if (this.filters && this.filters.missingValues === false)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  async selectBulkFolder()
  {
    const directory = await this.illustratorService.selectFolder();
    if (!directory)
    {
      return;
    }
    if (!this.filesystem.exists(directory))
    {
      this.feedbackService.notifyMessage(`The directory ${directory} does not exist or could not be selected`);
      return;
    }
    this.status = '';
    this.error = false;

    const baseFolder = directory.split(/\\/g);
    const files: any [] = [];
    const filesReaded = this.filesystem.readdirSync(directory , true , 'files');
    filesReaded.forEach( fp => files.push({path : fp}));
    const readedPaths = this.filesystem.readdirSync(directory , true , 'directories');
    for (const path of readedPaths)
    {
      const moreDirectories = this.filesystem.readdirSync(path , true , 'directories');
      if (moreDirectories)
      {
        moreDirectories.forEach( dp => readedPaths.push(dp));
      }
      const filepaths = this.filesystem.readdirSync(path , true , 'files');
      filepaths.forEach( fp => files.push({path : fp}));
    }
    this.bulkImportPath = directory;
    this.filesPicked(files);
    this.selectedFolder = '/' + baseFolder[baseFolder.length - 1];
  }

  private filesPicked(files: any[])
  {
    this.logService.trackTrace(`${files.length} files picked...`);
    let sel: SelectedFile[] = _.map(files, file =>
      {
      return {
        path: file.path,
        parsedPath: this.filesystem.parse(file.path),
        result: undefined
      };
    });
    sel = _.filter(sel, p => p.parsedPath.ext === '.ai');
    this.logService.trackTrace(`...${sel.length} ai files selected`);
    this.selection = sel;
  }

  async start()
  {
    if (this.importing || this.selection.length === 0)
    {
      return;
    }

    this.importing = true;
    this.cancelled = false;
    this.status = '';
    this.progress = 0;
    this.current = undefined;
    this.filters.tagChanged = this.changedTag;
    const weightOfOne = 1 / this.selection.length;

    for (let i = 0 ; i < this.selection.length ; i++)
    {
      this.status = `Processing file ${i} of ${this.selection.length}...`;
      this.current = this.selection[i];
      if (this.current.result && this.current.result.code === 1)
      {
        continue;
      }

      try
      {
        const result = await this.bulkService.import(this.filters, this.current , this.bulkImportPath , (perc, status) =>
        {
          const overallProgress = (i * 100 * weightOfOne) + (perc * weightOfOne);
          this.progress = overallProgress;
          if (this.cancelled)
          {
            this.status = 'Cancelling...';
          }
          else
          {
            this.status = `Processing ${i + 1} of ${this.selection.length}: ${status}`;
          }
        }, this.checkRules, this.checkPath, this.checkName);
        this.current.result = result;

        if (result.code === -1)
        {
          this.error = true;
        }

        if (this.cancelled)
        {
          this.status = `Cancelled by user`;
          this.feedbackService.notifyMessage('Cancelled by user');
          break;
        }
      }
      catch (err)
      {
        // this.feedbackService.notifyError("Unexpected error during bulk import, file " + this.current.path, err);
        this.logService.logException(err);
        this.notify('Unexpected error during bulk import, file ' + this.current.path);
      }
    }

    if (this.error)
    {
      this.status = 'Some files couldn\'t be imported';
    }
    else
    {
      this.status = 'Documents imported successfully';
      this.logService.trackTrace('Documents imported successfully', Severity.Verbose);
    }

    this.notify(this.status);

    this.progress = 0;
    // this.feedbackService.notifyMessage('Documents imported successfully');

    this.lastSelection = this.selection;
    this.filtersHasChanged = false;
    this.cancelled = false;
    this.importing = false;
    this.current = undefined;
    this.filters.missingValues = true;
  }

  canStart(): boolean
  {
    if ((!this.lastSelection || this.lastSelection !== this.selection ||
       this.filtersHasChanged) && !this.importing && this.selection.length > 0 && this.filters)
       {
      return true;
    }
    else
    {
      return false;
    }
  }

  cancel()
  {
    this.cancelled = true;
    this.status = 'Cancelling...';
  }

  close()
  {
    if (this.importing)
    {
      return;
    }
    this.dialogRef.close();
  }

  changeTag(tag)
  {

    this.changedTag = tag;
  }

  private notify (message: string): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(InfoPopupComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          message: message
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve());
    });
  }
}
