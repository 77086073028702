import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EventHubService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    standalone: false
})
export class WelcomeComponent implements OnInit
{
  constructor(private eventHubService: EventHubService) { }

  ngOnInit() {
  }

  openSideNav()
  {
    this.eventHubService.toggleSideNav.emit();
  }

  tutorial()
  {
    this.eventHubService.openTutorial.emit();
  }

}
