import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService
{
  languageChanged: EventEmitter<void> = new EventEmitter();

  constructor(private translate: TranslateService)
  {
    this.translate.addLangs(['es', 'en', 'pt', 'pl']);
    this.translate.setDefaultLang('en');
    this.translate.use(this.browserLanguage);
    this.translate.onLangChange.subscribe(() =>
    {
      if (this.uiChange)
      {
        this.languageChanged.emit();
      }
    });
  }

  get currentLanguage(): 'es' | 'en' | 'pt' | 'pl'
  {
    return <'es'|'en'|'pt'|'pl'>this.translate.currentLang;
  }

  get browserLanguage(): 'es' | 'en'
  {
    const language = <'es' | 'en'>this.translate.getBrowserLang();
    return language;
  }

  get locale(): 'en-US' | 'es-ES' | 'pt-BR' | 'pl-PL'
  {
    switch (this.currentLanguage)
    {
      case 'es':
        return 'es-ES';
      case 'pt':
        return 'pt-BR';
      case 'pl':
        return 'pl-PL';
      case 'en':
      default:
        return 'en-US';
    }
  }

  /**
   * This flag is used to suppress the first emit() that comes from the
   * automatic language selection in the constructor. If emitted,
   * the authService would use the default language for the current
   * user.
   */
  private uiChange: boolean = false;
  changeLanguage(language: 'es' | 'en' | 'pt' | 'pl')
  {
    this.uiChange = true;
    this.translate.use(language);
  }

  getTranslation(key: string, interpolateParams?: object): string
  {
    const translatedString = this.translate.instant(key , interpolateParams);
    return translatedString;
  }
}
