import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
        MoonTask,
        EventHubService,
        MoonDeskDocument,
        AuthService,
        DocumentPost,
        TaskAction,
        PermissionsService,
        UserAction
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
      import * as _ from 'underscore';
import { ShareBasket } from '../../../services/document-sharing.service';
import { ExportOptions } from '../../../_models/export-options';
import { UntypedFormControl } from '@angular/forms';

export interface SaveDocumentPopupData
{
  currentTask: MoonTask;
  currentDocument: MoonDeskDocument;
  relatedTasks: MoonTask[];
  isNewDoc: boolean;
  exportOptions: ExportOptions;
  missingFontsOrLinks: boolean;
}

export interface SaveDocumentPopupResult
{
  shareBasket: ShareBasket;
  documentPost: DocumentPost;
}

@Component({
    selector: 'app-save-document-popup',
    templateUrl: './save-document-popup.component.html',
    styleUrls: ['./save-document-popup.component.scss'],
    standalone: false
})

export class SaveDocumentPopupComponent implements OnInit {

  mode: 'minorVersion' | 'majorVersion' | 'approve';
  modeController: UntypedFormControl = new UntypedFormControl();
  isNewDoc: boolean;
  info: string;
  sendToReview: boolean = true;
  currentTask: MoonTask;
  currentDocument: MoonDeskDocument;
  relatedTasks: MoonTask[];

  missingFontsOrLinks: boolean;

  addToTask: boolean;
  exportOptions: ExportOptions = {};

  canApprove: boolean;

  constructor(public dialogRef: MatDialogRef<SaveDocumentPopupComponent>,
              private eventHub: EventHubService,
              private authService: AuthService,
              private permissionsService: PermissionsService,
              @Inject(MAT_DIALOG_DATA) public data: SaveDocumentPopupData)
  {
    this.currentTask = data.currentTask;
    this.currentDocument = data.currentDocument;
    this.relatedTasks = data.relatedTasks;
    this.isNewDoc = data.isNewDoc;
    this.missingFontsOrLinks = data.missingFontsOrLinks;
    if (this.currentTask && this.relatedTasks)
    {
      this.relatedTasks = _.filter(this.relatedTasks, task => task.id !== this.currentTask.id);
    }
    if (data.exportOptions)
    {
      this.exportOptions = data.exportOptions;
    }
    this.exportOptions.uploadFiles = true;
    this.exportOptions.pngFile = true;

    if (this.missingFontsOrLinks)
    {
      this.exportOptions.pdfFile = false;
      this.exportOptions.outlineFile = false;
    }
  }

  ngOnInit(): void
  {
    this.modeController.valueChanges.subscribe((mode: 'minorVersion' | 'majorVersion' | 'approve') =>
    {
      this.changeMode(mode);
    });
    this.modeController.setValue('majorVersion');
    this.canApprove = this.permissionsService.currentUserHasPermissionTo(UserAction.approveDocuments);
  }

  changeMode(mode: 'minorVersion' | 'majorVersion' | 'approve')
  {
    if ((this.mode === mode) ||
        (mode === 'approve' && !this.isApproveAllowed()) ||
        (mode === 'minorVersion' && this.isNewDoc))
    {
      return;
    }

    if (!this.isDocInTask() && mode !== 'minorVersion')
    {
      this.addToTask = true;
    }
    else if (mode === 'minorVersion')
    {
      this.addToTask = false;
    }
    this.mode = mode;
    this.updateInfo();
  }

  updateInfo()
  {
    // Task context infos
    if (!this.addToTask && !this.isDocInTask())
    {
      // Send to review checkbox need to be updated if uncheck the 'addToTask' field
      this.sendToReview = false;
    }
    if (this.currentTask && (this.isDocInTask() || this.addToTask))
    {
      switch (this.mode)
      {
        // case 'minorVersion':
        //   this.info = 'Working copies cannot be shared or sent to review.';
        //   break;
        case 'majorVersion':
          if (this.isDocInTask())
          {
            this.info = `The task nº ${this.currentTask.taskNumber} "${this.currentTask.name}" will be sent to review.`;
          }
          else if (this.sendToReview)
          {
            this.info = `The document will be added to the task nº ${this.currentTask.taskNumber}
                          "${this.currentTask.name}" and be sent to review.`;
          }
          else
          {
            this.info = `The document will be added to the task nº ${this.currentTask.taskNumber} "${this.currentTask.name}"`;
          }
          break;
        case 'approve':
          if (this.isDocInTask())
          {
            this.info = `The document will be marked as approved for task nº ${this.currentTask.taskNumber} "${this.currentTask.name}".`;
          }
          else
          {
            this.info = `The document will be added to the task nº ${this.currentTask.taskNumber}
                         "${this.currentTask.name}" and be marked as approved.`;
          }
          break;
        default:
          this.info = '';
      }
    }
    // no task context infos
    else
    {
      switch (this.mode)
      {
        // case 'minorVersion':
        //   this.info = 'Working copies cannot be shared or sent to review.';
        //   break;
        case 'majorVersion':
          this.info = `The document will be available in the MoonDesk Web App.`;
          break;
        case 'approve':
          this.info = `The document will be marked as approved and will be available in the MoonDesk Web App.`;
          break;
        default:
          this.info = '';
      }
    }
  }

  showTaskOptions(): boolean
  {
    if (this.mode !== 'minorVersion' && (this.currentTask || (this.relatedTasks && this.relatedTasks.length > 0)))
    {
      return true;
    }
    return false;
  }

  openTaskView(task: MoonTask)
  {
    const subTask = _.find(task.subTasks, st => st.documentId === this.currentDocument.id);
    this.eventHub.openTaskView.emit({taskId: task.id, subTaskId: subTask.id});
  }

  // Get currentTask + relatedTask
  private getAllTasks(): MoonTask[]
  {
    let allTasks: MoonTask[] = [];
    if (this.currentTask)
    {
      allTasks.push(this.currentTask);
    }
    if (this.relatedTasks && this.relatedTasks.length > 0)
    {
      allTasks = allTasks.concat(this.relatedTasks);
    }
    return allTasks;
  }

  isDocInTask(): boolean
  {
    if (!this.isNewDoc && this.currentDocument && this.currentTask &&
        _.any(this.currentTask.subTasks, subTask => subTask.documentId === this.currentDocument.id))
    {
      return true;
    }
    return false;
  }

  statusOfDocAsSubTask()
  {
    if (!this.currentTask)
    {
      return '';
    }
    const st = _.find(this.currentTask.subTasks , sbtsk => sbtsk.documentId === this.currentDocument.id);
    const status = st && st.status ? st.status : undefined;
    return status;
  }

  isApproveAllowed(): boolean
  {
    const userId = this.authService.getCurrentIdentity().user.id;
    const allTasks = this.getAllTasks();
    if (this.canApprove && ((allTasks.length === 0) || _.all(allTasks, task => !task.ownerId || task.ownerId === userId)))
    {
      return true;
    }
    return false;
  }

  cancel()
  {
    this.dialogRef.close();
  }

  save()
  {
    // The jpg will always be generated in the backend
    this.exportOptions.jpgFile = false;

    const taskActions: TaskAction[] = [];
    if (this.currentTask && this.mode !== 'minorVersion' && (this.isDocInTask() || this.addToTask))
    {
      const taskAction: TaskAction =
      {
        reviewAssignments: this.currentTask.reviewerAssignments,
        markAsDone: this.mode === 'approve',
        sendToReview: this.mode === 'majorVersion' && this.sendToReview,
        taskId: this.currentTask.id
      };
      taskActions.push(taskAction);
    }
    const documentPost: DocumentPost =
    {
      document: this.currentDocument,
      isMayorVersion: this.mode === 'majorVersion' || this.mode === 'approve',
      markApproved: this.mode === 'approve',
      taskActions: taskActions,
      uploadedFromWeb : false
    };
    const result: SaveDocumentPopupResult =
    {
      documentPost: documentPost,
      shareBasket: this.mode !== 'minorVersion' ?
        {
          exportFiles: [],
          exportOptions: this.exportOptions,
          tempFolder: null,
          localDownloadFolder: null
        } : undefined
    };
    this.dialogRef.close(result);
  }
}
