import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,  MatDialog } from '@angular/material/dialog';
import {
        ExtensionService,
        EventHubService
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FilesystemService } from 'src/app/services/filesystem.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    selector: 'app-new-version-dialog',
    templateUrl: './new-version-dialog.component.html',
    styleUrls: ['./new-version-dialog.component.scss'],
    standalone: false
})
export class NewVersionDialogComponent
{
  progress: number;
  busy: boolean;
  status: string;

  availableVersion: string = 'unknown';
  localVersion: string = 'unknown';
  allowLater: boolean = true;
  downloaded: boolean = false;
  downloadFilepath = '';

  constructor
  (
    public dialogRef: MatDialogRef<NewVersionDialogComponent>,
    private extensionService: ExtensionService,
    private fileSysService: FilesystemService,
    private eventHubService: EventHubService,
    private feedbackService: FeedbackService,
    @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    if (data)
    {
      this.availableVersion = data.availableVersion;
      this.localVersion = data.localVersion;
      this.allowLater = data.allowLater;
    }
  }

  async openInstallerFolder()
  {
    this.fileSysService.open(this.downloadFilepath);
  }


  async download()
  {
    try
    {
      this.downloaded = false;
      this.busy = true;
      this.progress = 0;
      const fileVersion = await this.extensionService.getExtensionVersion();
      // Download ZXP for Mac and Windows 1.20
      const fileContent = await this.extensionService.downloadExtension(perc =>
      {
        this.status = 'Download in progress...';
        this.progress = perc;
      });
      const home = this.fileSysService.getHomeDirectory();
      const download = this.fileSysService.join(home, 'Downloads');
      const filepath = this.fileSysService.join(download, fileVersion.extensionName);
      await this.fileSysService.writeFileAsync(filepath, fileContent, {encoding: 'base64'});
      this.downloadFilepath = filepath;
      this.feedbackService.notifyMessage('Download ready. Please open download folder and install ZXP');
      this.downloaded = true;
      this.status = '';
      this.busy = false;
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error downloading Moondesk', err);
      this.status = err;
      this.progress = -1;
    }
  }

  goToManual()
  {
    this.eventHubService.openTutorial.emit();
    this.close();
  }

  close()
  {
    if (this.allowLater)
    {
      this.dialogRef.close();
    }
  }
}

