import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'underscore';
import { Company, Identity, AuthService, CompanyConfigurationService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import {  MatDialog } from '@angular/material/dialog';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';
import { FeedbackService } from 'src/app/services/feedback.service';


@Component({
    selector: 'company-selector',
    templateUrl: './company-selector.component.html',
    styleUrls: ['./company-selector.component.scss'],
    standalone: false
})
export class CompanySelectorComponent implements OnInit
{
  busy: boolean;
  lastCompany: Company;
  company: UntypedFormControl;
  companies: Company[];
  filteredCompanies: Company[];

  isSelectorOpen = false;
  hasMoreThanOneCompany = false;

  private noEventOptions = { onlySelf: true, emitEvent: false};

  isSuperUser: boolean = false;
  @Input() showIcons: boolean;
  @Input() set identity(identity: Identity)
  {
    if (identity && identity.assignments && identity.assignments.length > 0)
    {
      if (identity.user && identity.user.isSuperUser) {
        this.isSuperUser = true;
      }

      const comps = _.map(identity.assignments, as => as.company);
      this.companies = _.sortBy(comps, c => c.name.toLowerCase());
      if (identity.company)
      {
        const comp = _.find(this.companies, c => c.id == identity.company.id);
        if (comp && comp.name !== identity.company.name)
        {
          comp.name = identity.company.name;
        }
        this.company.setValue(comp, this.noEventOptions);
      }
      else
      {
        this.company.setValue(undefined, this.noEventOptions);
      }
    }
    else
    {
      this.companies = [];
      this.company.setValue(undefined, this.noEventOptions);
    }
    this.hasMoreThanOneCompany = this.companies.length > 1;
    this.filterCompanies('');
    this.lastCompany = this.company.value;
  }

  @ViewChild('workspaceInput') workspaceInput: ElementRef;

  constructor(public dialog: MatDialog, private authService: AuthService,
    private configService: CompanyConfigurationService,
    private feedbackService: FeedbackService)
  {
    this.company  = new UntypedFormControl(undefined, Validators.required);
    this.company.valueChanges.subscribe(this.companySelected);
  }

  ngOnInit() {
  }

  companySelected = async (company: Company) =>
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      this.company.disable(this.noEventOptions);
      await this.authService.selectCompany(company, true);
    }
    catch (err)
    {
      let msg = 'Error loading documents';
      if (err.error)
      {
        msg = err.error;
      }
      this.feedbackService.notifyError(msg, err);
      this.company.setValue(this.lastCompany, this.noEventOptions);
    }
    finally
    {
      this.company.enable(this.noEventOptions);
      this.lastCompany = this.company.value;
      this.busy = false;
    }
  }

  async refresh()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      this.company.disable(this.noEventOptions);
      await this.authService.refreshAssignments();
      this.company.enable(this.noEventOptions);
    }
    catch (err)
    {
      let msg = 'Error loading documents';
      if (err.error)
      {
        msg = err.error;
      }
      this.feedbackService.notifyError(msg, err);
    }
    finally
    {
      this.company.enable(this.noEventOptions);
      this.busy = false;
    }
  }

  async addCompany(){
    const newCompany = await this.companyDialog();
    if (!newCompany) {
      return;
    }
    this.busy = true;
    try
    {
      await this.configService.addCompany(newCompany);
      this.busy = false;
    }
    catch (err)
    {
      let msg = 'Error adding company';
      if (err && err.error)
      {
        msg = err.error;
      }
      this.feedbackService.notifyError(msg, err);
      this.busy = false;
    }
  }

  async deleteCompany()
  {
    if (!this.company.value) { return; }
    const answer = await this.askDialog(`Do you really want to delete the company ${this.company.value.name}?`);
    if (answer == 'yes')
    {
      try
      {
        const deleted = await this.configService.deleteCompany(this.company.value);
        if (deleted)
        {
          this.companies.splice(this.companies.indexOf(this.company.value), 1);
          this.company.setValue(undefined);
          await this.feedbackService.notifyMessage('Company was deleted');
        }
        else
        {
          await this.feedbackService.notifyMessage('Company could not be deleted, please delete all labels first!');
        }
      }
      catch (err)
      {
        let msg = 'Error deleting company';
        if (err && err.error)
        {
          msg = err.error;
        }
        await this.feedbackService.notifyError(msg, err);
      }
    }
  }

  async editCompany()
  {
    try
    {
      let newCompany = await this.companyDialog(_.clone(this.company.value));
      if (!newCompany) {
        return;
      }
      newCompany = await this.configService.updateCompany(newCompany);
      if (newCompany)
      {
        this.busy = true;
        const index = this.companies.indexOf(this.company.value);
        this.companies.splice(index, 1, newCompany);
        this.companies = _.sortBy(this.companies, c => c && c.name ? c.name.toLowerCase() : '');
        this.company.setValue(newCompany);
        this.authService.selectCompany(newCompany);
        this.busy = false;
      }
    }
    catch (err)
    {
      const msg = err && err.message ? err.message : 'Error changing company name';
      this.feedbackService.notifyMessage(msg);
    }
  }

  filterCompanies(searchValue: string)
  {
    const identity = this.authService.getCurrentIdentity();
    if (!searchValue || (searchValue && searchValue.length === 0))
    {
      this.filteredCompanies = _.filter(this.companies , c => c.id !== identity.company.id);
    }
    else
    {
      const normalizedText = searchValue.normalize('NFD').replace(/\p{Diacritic}/gu, '');
      this.filteredCompanies = _.filter(this.companies , c => c.name
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLowerCase()
          .includes(normalizedText.toLowerCase())
        && c.id !== identity.company.id);
    }
  }

  onCompanyOptionClick(company: Company)
  {
    this.isSelectorOpen = false;
    this.company.setValue(company);
  }

  async onCompanyNameClick()
  {
    if (!this.hasMoreThanOneCompany)
    {
      return;
    }
    if (this.isSelectorOpen)
    {
      this.isSelectorOpen = false;
    }
    else
    {
      this.isSelectorOpen = true;
      await this.delay(10);
      this.workspaceInput.nativeElement.focus();
    }
  }

  async closeOverlay()
  {
    // Fixes synchronization problem between the close of our button and the close when clicking outside the overlay.
    await this.delay(10);
    this.isSelectorOpen = false;
  }

  private companyDialog(company: Company = undefined): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      const dialogRef = this.dialog.open(CompanyDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          company: company
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  private askDialog(question: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  private delay(ms: number)
  {
    return new Promise( resolve => setTimeout(resolve, ms));
  }
}
