import { Component, Input } from '@angular/core';
import {
  MoonDeskDocument,
  AuthService,
  EventHubService,
  TaskView
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { WorkManagerService, WorkManagerStatus, WorkManagerState } from './work-manager/work-manager.service';
import { CurrentDocumentService, CurrentDocumentChangedEvent } from './current-document-manager/current-document.service';
import { IllustratorService } from '../../services/illustrator.service';
import { BulkService } from '../../services/bulk.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'work',
    templateUrl: './work.component.html',
    styleUrls: ['./work.component.scss'],
    standalone: false
})
export class WorkComponent {

  currentDocument: MoonDeskDocument;
  docFromTask: boolean;

  busy: boolean;

  workManagerStatus: WorkManagerStatus;

  /**
   * Only for mode === 'Edit'
   */
  keepTagsForNewVersion: boolean;

  mode: 'Edit' | 'Import';
  @Input() set workMode(mode: 'Edit' | 'Import')
  {
    this.mode = mode;
  }

  constructor(private authService: AuthService,
              private workManagerService: WorkManagerService,
              private currentDocService: CurrentDocumentService,
              private illustratorService: IllustratorService,
              private bulkService: BulkService,
              private navigationService: NavigationService,
              private eventHub: EventHubService)
  {
    this.currentDocService.documentChange.subscribe((e: CurrentDocumentChangedEvent) => {
      if (e.status === 'done')
      {
        this.currentDocument = this.mode === 'Import' ? e.importDocument : e.editDocument;
      }
    });

    this.workManagerStatus = this.workManagerService.status;
    this.workManagerService.statusChange.subscribe(s => this.workManagerStatus = s);
    this.currentDocument = this.mode === 'Import'
                    ? this.currentDocService.importDocument
                    : this.currentDocService.editDocument;
  }

  isTaskOpen(): boolean
  {
    return this.workManagerService.currentTask != undefined;
  }

  taskHasDocuments(): boolean
  {
    const t = this.workManagerService.currentTask;
    return t != undefined && t.subTasks != undefined && _.any(t.subTasks, st => st.status != 'Closed');
  }

  openCurrentTasksDocuments()
  {
    const t = this.workManagerService.currentTask;
    if (t)
    {
      const taskView: TaskView = {task: t};
      this.eventHub.goToWorkEdit.emit(taskView);
    }
  }

  showImportHelp(): boolean
  {
    return this.mode == 'Import'
           && this.workManagerStatus.status == WorkManagerState.done
           && !this.currentDocument;
  }

  showEditFromQueue(): boolean
  {
    return this.mode == 'Edit'
           && !this.currentDocument
           && this.workManagerStatus.status == WorkManagerState.done
           && this.workManagerService.getQueue().length > 0;
  }

  showEditFromSearch(): boolean
  {
    return this.mode == 'Edit'
           && !this.currentDocument
           && this.workManagerStatus.status == WorkManagerState.done
           && this.workManagerService.getQueue().length == 0;
  }

  showDownloadProgress(): boolean
  {
    return this.workManagerStatus.status !== WorkManagerState.done && !this.workManagerStatus.saveDocumentStatus;
  }

  downloadProgressMode(): 'determinate'|'indeterminate'
  {
    if (this.workManagerService.status.progress && this.workManagerService.status.progress > 0 && this.workManagerService.status.progress < 100)
    {
      return 'determinate';
    }
    return 'indeterminate';
  }

  illustratorMenuCommand(action: 'new' | 'open')
  {
    this.illustratorService.executeMenuCommand(action);
  }

  bulkImportClicked = async () =>
  {
    await this.bulkService.openBulkImport();
  }

  navigate(goTo: 'search' | 'tasks')
  {
    if (goTo === 'search')
    {
      this.navigationService.goToMyLabels();
    }
    else if (goTo === 'tasks')
    {
      this.navigationService.goToTasks();
    }
  }

  hasComm(): boolean
  {
    const id = this.authService.getCurrentIdentity();
    return id.company?.subscriptionPlan?.hasTaskModule
  }

  shouldKeepTags(): boolean
  {
    if (this.mode === 'Import')
    {
      return true;
    }
    else
    {
      return this.keepTagsForNewVersion;
    }
  }
}
