import { Component, Inject, ViewChild } from '@angular/core';
import {
   MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import * as _ from 'underscore';
import {
  DocumentVersion,
  DocumentService,
  EventHubService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { TagEditDialogComponent } from '../_shared/tag-edit-dialog/tag-edit-dialog.component';
import { RuleHelperService } from '../../services/rule-helper.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    selector: 'app-picture-viewer',
    templateUrl: './picture-viewer.component.html',
    styleUrls: ['./picture-viewer.component.scss'],
    standalone: false
})
export class PictureViewerComponent {

  @ViewChild('group', { static: false }) group;

  loading: boolean;
  documentId: string;
  versions: DocumentVersion[];
  currentVersion: DocumentVersion;
  showEdit: boolean;
  allowEdit: boolean = true; // DEV PURPOSE

  constructor(private docService: DocumentService,
        private dialogRef: MatDialogRef<PictureViewerComponent>,
        private eventHub: EventHubService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ruleHelperService: RuleHelperService,
        private dialog: MatDialog,
        private feedbackService: FeedbackService)
  {
    this.documentId = data.documentId;
    this.showEdit = data.showEdit;
    this.loadData();
  }

  private async loadData()
  {
    try
    {
      this.loading = true;
      console.log(`Getting label versions...`);
      this.versions = await this.docService.getDocumentVersions(this.documentId);
      console.log(`Getting all image urls...`);
      this.versions?.forEach(async v => v.imageUrl = await this.docService.getFullImageUrl(v));
      console.log(`Searching latest...`);
      const latest = <DocumentVersion>_.max(this.versions, v => v.versionNumber);
      this.loading = false;
      this.chooseVersion(latest);
    }
    catch (error)
    {
      this.feedbackService.notifyError('Error getting preview' , error);
      this.loading = false;
    }
  }

  size: string = 'fit';
  setSize(size?: string) {
    if (!size) {
      this.size = this.size === 'fit' ? 'full' : 'fit';
    }
    else {
      this.size = size;
    }
  }

  async chooseVersion(version: DocumentVersion) {
    try
    {
      this.currentVersion = undefined;
      this.currentVersion = version;
    } catch (error)
    {
      this.feedbackService.notifyError('Error getting picture' , error);
    }
  }

  getTimestamp(date: Date) {
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} - ${date.toLocaleTimeString()}`;
  }


  getDate(version: DocumentVersion): string
  {
    if (version.timestampUtc)
    {
      let date: any = version.timestampUtc.getDate();
      if (date < 10) { date = `0${date}`; }
      let month: any = version.timestampUtc.getMonth() + 1;
      if (month < 10) { month = `0${month}`; }
      let year: any = version.timestampUtc.getFullYear() % 100;
      if (year < 10) { year = `0${year}`; }
      return `${date}/${month}/${year}`;
    }
    else
    {
      return 'dd/mm/yy';
    }
  }

  getTags(version: DocumentVersion): string
  {
    if (!version || !version.documentTags || version.documentTags.length === 0)
    {
      return '-';
    }
    return _.map(version.documentTags, t => t.value).join(', ');
  }

  async edit(versionToEdit: DocumentVersion)
  {
    try
    {
      this.loading = true;
      const document = await this.docService.getDocument(this.documentId);
      document.editingVersion = versionToEdit;
      this.eventHub.goToWorkEdit.emit([document]);
      this.dialogRef.close();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error loading document', err);
    }
    finally
    {
      this.loading = false;
    }
  }

  async showRuleResults(version: DocumentVersion)
  {
    await this.ruleHelperService.showResultsDialog(version.ruleResults, false);
  }

  resultStatus(version: DocumentVersion): 'none' | 'allok' | 'error'
  {
    if (version.ruleResults && version.ruleResults.length > 0)
    {
      const appliedRules = _.filter(version.ruleResults, rr => rr.applied);
      if (_.all(appliedRules, rr => !rr.error))
      {
        return 'allok';
      }
      return 'error';
    }
    return 'none';
  }

  close()
  {
    this.dialogRef.close();
  }

  getStatusTooltip(version: DocumentVersion)
  {
    switch (version.status)
    {
      case 'Draft':
        return 'Document is a draft';
      case 'Approved':
        if (version.approvedRejectedBy)
        {
          return `Document is approved by ${version.approvedRejectedBy.username}`;
        }
        else
        {
         return 'Document is approved';
        }
    }
  }

  getPreviousImageURL(): string
  {
    let result: string;
    const previousVersionNumber = this.currentVersion.versionNumber - 1;
    if (previousVersionNumber > 0)
    {
      const previousVersion = _.find(this.versions, ver => ver.versionNumber === previousVersionNumber);
      result = previousVersion.imageUrl;
    }
    return result;
  }

  showEditTags(version: DocumentVersion){
    const dialogRef = this.dialog.open(TagEditDialogComponent, {
      width: '50%',
      minWidth: '50%',
      data: {
        docVersion: version
      },
      disableClose: true
    });
  }
}
