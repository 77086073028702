import { Component, OnInit } from '@angular/core';
import { IllustratorService } from '../../../services/illustrator.service';
import { Configuration } from '../../../_models/Configuration';
import { IllustratorConfigService, tempFolderName } from '../../../services/illustrator-config.service';

import * as _ from 'underscore';
import { MatDialogRef,  MatDialog } from '@angular/material/dialog';
import {
        AuthService,
        ConnectionService,
        User,
        BackendInfo,
        LoggingService,
        DocumentService,
        FrontendInfo
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FilesystemService } from '../../../services/filesystem.service';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';
import { LibraryCacheService } from '../../../services/library-cache.service';
import { WorkManagerService } from '../../work/work-manager/work-manager.service';
import { environment } from 'src/environments/environment';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    standalone: false
})
export class ConfigurationComponent implements OnInit {

  profileBusy: boolean = false;
  cacheBusy: boolean = false;

  user: User = undefined;
  configuration: Configuration = undefined;
  profileMessage: string;

  frontendInfo: FrontendInfo;
  backendInfo: BackendInfo;

  constructor(public dialogRef: MatDialogRef<ConfigurationComponent>,
    private authService: AuthService,
    private connService: ConnectionService,
    private illustratorService: IllustratorService,
    private illConfigService: IllustratorConfigService,
    private feedbackService: FeedbackService,
    private logger: LoggingService,
    private fileSysService: FilesystemService,
    private docService: DocumentService,
    private dialog: MatDialog,
    private libraryCacheService: LibraryCacheService,
    private workMgrService: WorkManagerService)
    { }

  async ngOnInit()
  {
    this.configuration = await this.illConfigService.getConfiguration();
    this.user = await this.illConfigService.getCurrentUser();
    await this.updateSysInfo();
    this.frontendInfo = await this.illConfigService.getFrontendInfo();
  }

  async testConnection()
  {
    try
    {
      const backendInfo = await this.connService.connectionTest(environment.backendUrl);
      this.illustratorService.alert(`Connection established (${backendInfo.cloudName} - version ${backendInfo.version})`);
    }
    catch (err)
    {
      this.illustratorService.alert('Connection failure: ' + err.error);
    }
  }

  // async save()
  // {
  //   const url = this.configuration.backendUrl;
  //   if (url.endsWith('/'))
  //   {
  //     this.configuration.backendUrl = url.substr(0, url.length - 2);
  //   }
  //   const original = await this.illConfigService.getConfiguration();
  //   await this.illConfigService.setConfiguration(this.configuration);
  //   this.feedbackService.notifyMessage('Settings saved');
  //   if (original.backendUrl !== this.configuration.backendUrl)
  //   {
  //     await this.authService.signOut();
  //   }
  //   this.dialogRef.close();
  // }

  // async discard()
  // {
  //   this.configuration = await this.illConfigService.getConfiguration();
  //   this.dialogRef.close();
  // }

  // default()
  // {
  //   this.configuration = this.illConfigService.getDefaultConfiguration();
  // }

  goToDebug()
  {
    this.illustratorService.openUrl('http://localhost:9090');
  }

  async updateSysInfo()
  {
    try
    {
      this.backendInfo = await this.connService.connectionTest(environment.backendUrl);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Connection test error', err);
      this.backendInfo = undefined;
    }
  }

  openTempFolder()
  {
    const tempDirectoryPath = this.configuration.tempDirectory;
    this.fileSysService.open(tempDirectoryPath);
  }

  async deleteTempFolder()
  {
    if (this.cacheBusy || !this.configuration)
    {
      return;
    }
    const answer = await this.ask('Are you sure you want to delete all the local files?');
    if (answer)
    {
      try
      {
        this.cacheBusy = true;
        await this.workMgrService.closeEverything();
        const cachePath = this.configuration.tempDirectory;
        const cacheFolder = this.fileSysService.filenameOf(cachePath);
        if (cacheFolder !== tempFolderName)
        {
          // Should not happend, but better check before delete folder recursively
          throw new Error('ERROR: No temp folder');
        }
        await this.fileSysService.deleteFolderRecursive(cachePath);
        this.feedbackService.notifyMessage('Cache folder removed successfully');
      }
      catch (err)
      {
        const errorMsg = err.message ? err.message : 'Error deleting cache';
        this.feedbackService.notifyError(errorMsg, err);
      }
      finally
      {
        this.libraryCacheService.updateLibrary();
        this.cacheBusy = false;
      }
    }
  }

  async updateTempFolder()
  {
    const answer = await this.ask('Are you sure you want to change your cache folder path?');
    if (answer)
    {
      try
      {
        this.cacheBusy = true;
        await this.workMgrService.closeEverything();
        const newDirectory = await this.illustratorService.selectFolder();
        if (!newDirectory)
        {
          return;
        }
        if (!this.fileSysService.exists(newDirectory))
        {
          this.feedbackService.notifyMessage(`The directory ${newDirectory} does not exist or could not be selected`);
        }
        const newPath = this.fileSysService.join(newDirectory, tempFolderName);
        const oldPath = this.configuration.tempDirectory;
        if (this.fileSysService.exists(oldPath))
        {
          await this.fileSysService.moveFolder(oldPath, newDirectory);
        }
        this.configuration.tempDirectory = newPath;
        await this.illConfigService.setConfiguration(this.configuration);
        this.feedbackService.notifyMessage(`Cache directory path updated successfully. New path: ${newPath}`);
      }
      catch (err)
      {
        const errorMsg = err.message ? err.message : 'Error updating cache folder';
        this.feedbackService.notifyError(errorMsg, err);
      }
      finally
      {
        this.libraryCacheService.updateLibrary();
        this.cacheBusy = false;
      }
    }
  }

  async test1()
  {
    try
    {
      const dark = await this.illustratorService.isDarkTheme();
      console.log(`Dark: ${dark}`);
      // await this.illustratorService.getOpenDocuments();
      // throw Error("Fatal error");
      // const tag = await this.docService.addDocumentTag({value: 'mytesttoday',
      //             documentVersionId: 'd837c544-52ac-4087-a6f5-1b4a88808581'});
      // console.log(tag);
    }
    catch (err)
    {
      // this.feedbackService.notifyError("Test error", err);
      console.log(err);
    }
  }

  async test2()
  {
    try
    {
      await this.docService.deleteDocumentTag('40d46f51-2ea2-4dcf-ae5c-bda31134bbef');
    }
    catch (err)
    {
      console.log(err);
    }
  }

  async test3()
  {
    const fonts = await this.fileSysService.getPlatformFonts();
    console.log(fonts);
  }

  onNoClick(): void
  {
    this.dialogRef.close();
  }

  private ask(question: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'yes'));
    });
  }
}
