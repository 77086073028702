import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import {
        User,
        Identity,
        TaskFilter,
        AuthService
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MoonDatepickerHeaderComponent } from '../../_shared/moon-datepicker/moon-datepicker-header.component';

@Component({
    selector: 'app-task-search',
    templateUrl: './task-search.component.html',
    styleUrls: ['./task-search.component.scss'],
    standalone: false
})
export class TaskSearchComponent implements OnInit {

  filterController: UntypedFormGroup;
  filterBy: AbstractControl;
  archived: AbstractControl;
  reverseOrder: AbstractControl;
  searchText: AbstractControl;
  statuses: AbstractControl;
  minDate: AbstractControl;
  maxDate: AbstractControl;
  assignedUserId: AbstractControl;
  taskNumber: AbstractControl;
  orderBy: AbstractControl;

  @Input() companyUsers: User[];
  identity: Identity;


  moonDatePickerHeader = MoonDatepickerHeaderComponent;


  lastFilter: TaskFilter;
  dirty: boolean;

  _busy: boolean;
  @Input() set busy(b: boolean)
  {
    this._busy = b;
    if (this._busy)
    {
      this.filterController.disable();
    }
    else
    {
      this.filterController.enable();
    }
  }

  @Input() set filter(f: TaskFilter)
  {
    this.filterController.patchValue({
      filterBy: f.filterBy,
      archived: f.archived,
      reverseOrder : f.reverseOrder,
      searchText: f.searchText,
      statuses: f.statuses,
      assignedUserId: f.assignedUserId,
      taskNumber: f.taskNumber,
      minDate: f.minDate,
      maxDate: f.maxDate,
      orderBy : f.orderBy
    });
  }
  @Input() showMyTasksSwitch: boolean;
  @Output() resetTaskList: EventEmitter<void> = new EventEmitter<void>();
  @Output() filterChange: EventEmitter<TaskFilter> = new EventEmitter<TaskFilter>();
  @Output() search: EventEmitter<TaskFilter> = new EventEmitter<TaskFilter>();


  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('filterTemplate') filterTemplate: TemplateRef<HTMLInputElement>;
  overlayRef: OverlayRef;
  private subs: Subscription [] = [];
  constructor(private fb: UntypedFormBuilder, private _viewContainerRef: ViewContainerRef,
    private overlay: Overlay, private authService: AuthService)
  {

    this.filterController = this.fb.group({
      filterBy: undefined,
      archived: undefined,
      searchText: undefined,
      statuses: undefined,
      assignedUserId: undefined,
      taskNumber: undefined,
      minDate: undefined,
      maxDate: undefined,
      orderBy : undefined,
      reverseOrder : undefined
    });
    this.filterBy = this.filterController.get('filterBy');
    this.archived = this.filterController.get('archived');
    this.searchText = this.filterController.get('searchText');
    this.statuses = this.filterController.get('statuses');
    this.assignedUserId = this.filterController.get('assignedUserId');
    this.taskNumber = this.filterController.get('taskNumber');
    this.minDate = this.filterController.get('minDate');
    this.maxDate = this.filterController.get('maxDate');
    this.orderBy = this.filterController.get('orderBy');
    this.reverseOrder = this.filterController.get('reverseOrder');
    this.filterController.valueChanges.subscribe((filter: TaskFilter) => this.setTaskFilter(filter));
    this.subs.push(this.authService.identityChanged.subscribe(identity =>
    {
      if (identity && identity.user)
      {
        this.identity = identity;
        this.resetFilter();
      }
    }));
  }

  ngOnInit()
  {
    this.requestSearch();
  }


  setTaskFilter(filter: TaskFilter)
  {
    if (this.lastFilter && !_.isMatch(filter, this.lastFilter))
    {
      this.dirty = true;
      this.filterChange.emit(filter);
    }
    this.lastFilter = filter;
  }

  clearValue(field: 'minDate'|'maxDate'|'searchText'|'statuses'|'assignedUserId'|'taskNumber'| 'filterBy'|'orderBy'| 'reverseOrder')
  {
    switch (field)
    {
      case 'minDate':
        this.minDate.setValue(undefined);
        break;
      case 'maxDate':
        this.maxDate.setValue(undefined);
        break;
      case 'searchText':
        this.searchText.setValue(undefined);
        break;
      case 'statuses':
        this.statuses.setValue(undefined);
        break;
      case 'assignedUserId':
        this.assignedUserId.setValue(undefined);
        break;
      case 'taskNumber':
        this.taskNumber.setValue(undefined);
        break;
      case 'filterBy':
        this.filterBy.setValue('MyTasks');
        break;
      case 'orderBy':
        this.orderBy.setValue('Timestamp');
        break;
      case 'reverseOrder':
        this.reverseOrder.setValue(false);
        break;
    }
  }

  removeAssigned(assignedId: string)
  {
    const assignedIds: string[] = this.assignedUserId.value;
    if (assignedIds && assignedIds.length > 1)
    {
      const toRemove = assignedIds.find(v => v === assignedId);
      const index = assignedIds.indexOf(toRemove);
      assignedIds.splice(index, 1);
      this.assignedUserId.setValue(assignedIds);
    }
    else
    {
      this.clearValue('assignedUserId');
    }
  }

  toggleOverlay()
  {
    const template = this.filterTemplate;
    const elementRef = this.searchInput;
    const portal = new TemplatePortal(template, this._viewContainerRef);
    if (this.overlayRef)
    {
      this.closeOverlay();
    }
    else
    {
      this.dirty = false;
      const connectedStrategy = this.overlay.position()
        .flexibleConnectedTo(elementRef)
        .withPositions([{originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 10}]);
      this.overlayRef = this.overlay.create({
        positionStrategy: connectedStrategy,
        // width: elementRef.nativeElement.offsetWidth,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop'
      });
      this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
      this.overlayRef.attach(portal);
    }
  }

  closeOverlay()
  {
    if (this.overlayRef)
    {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
    if (this.dirty)
    {
      this.requestSearch();
    }
  }

  resetFilter()
  {
    if (this.busy)
    {
      return;
    }
    this.clearValue('taskNumber');
    this.clearValue('statuses');
    this.clearValue('searchText');
    this.clearValue('minDate');
    this.clearValue('maxDate');
    this.clearValue('assignedUserId');
    this.clearValue('filterBy');
    this.clearValue('orderBy');
    this.clearValue('reverseOrder');
    this.archived.setValue(false);
    this.requestSearch();
    this.search.emit();
  }

  requestSearch()
  {
    this.dirty = false;
    this.search.emit();
    this.closeOverlay();
  }

  toggleReverseorderBy()
  {
    this.reverseOrder.setValue(!this.reverseOrder.value);
  }

  setOrderBy(value: 'Timestamp' | 'ExpirationDate' | 'LastUpdate')
  {
    this.orderBy.setValue(value);
    this.requestSearch();
  }

  setFilterBy(value: 'MyTasks' | 'MyPendings' | 'All')
  {
    this.filterBy.setValue(value);
    this.requestSearch();
  }

  getUsernameById(userId: string)
  {
    const user = _.find(this.companyUsers, cu => cu.id === userId);
    let text = 'n/a';
    if (user)
    {
      text = user.username ? user.username : user.email;
    }
    return text;
  }

  removeArchivedValue()
  {
    this.archived.setValue(false);
  }

  removeStatus(status: 'InWork' | 'InReview' | 'Done')
  {
    this.statuses.setValue(_.without(this.statuses.value, status));
  }

  showResetFilter()
  {
    return this.archived.value === true || this.orderBy.value !== 'Timestamp' || this.filterBy.value !== 'MyTasks' || this.minDate.value ||
           this.maxDate.value || this.assignedUserId.value || (this.searchText.value && this.searchText.value.length > 0) ||
           this.taskNumber.value || this.statuses.value;
  }
}
