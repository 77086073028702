import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-ask-dialog',
    templateUrl: './ask-dialog.component.html',
    styleUrls: ['./ask-dialog.component.scss'],
    standalone: false
})
export class AskDialogComponent
{
  constructor(
    public dialogRef: MatDialogRef<AskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
    {
    }

  onNoClick(): void
  {
    this.dialogRef.close();
  }

}
