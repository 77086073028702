import { Injectable, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import {  MatDialog } from '@angular/material/dialog';
import { Stopwatch } from 'ts-stopwatch';
import { ParsedPath } from '../../../_node/Node';
import { MoonDeskDocument,
         DocumentVersion,
         FieldValue,
         LoggingService,
         DocumentService,
         AuthService,
         DocumentPost,
         MoonTask,
         DocumentTag,
         DocumentFilter,
         Progress,
         Severity,
         LinkReplacement,
         DocumentRawData,
         DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE,
         ExportFilesIdentifiers,
         DocumentVersionMetadata,
         TranslationService,
         EventHubService} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { ShareBasket, DocumentSharingService } from '../../../services/document-sharing.service';
import { IllustratorItem } from '../../../_models/smartmapping';
import { IllustratorService, KeyValuePair } from '../../../services/illustrator.service';
import { FilepathService } from '../../../services/filepath.service';
import { FilesystemService, PlatformFont } from '../../../services/filesystem.service';
import { RuleResult } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { LibraryCacheService } from '../../../services/library-cache.service';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';
import { SaveDocumentPopupComponent,
         SaveDocumentPopupData,
         SaveDocumentPopupResult } from '../save-document-popup/save-document-popup.component';
import { RuleHelperService } from '../../../services/rule-helper.service';
import { RulesExecutionService } from '../../../services/rules/rules-execution.service';
import { FontsHandlerDialogComponent } from '../../_shared/fonts-handler-dialog/fonts-handler-dialog.component';
import { FeedbackService } from 'src/app/services/feedback.service';


export interface SaveDocumentOptions
{
  mode: 'NewDoc' | 'NewVersion';
  saveAndClose?: boolean;
  suppressVersionCheck?: boolean;
  suppressDuplicateRule?: boolean;
  suppressRuleConfirmation?: boolean;
  suppressShareDialog?: boolean;
  suppressCompleteClassSelection?: boolean;
  /**
   * If true and a document with that checksum already exists in the DB,
   * the document won't be uploaded (only for new documents)
   */
  checkChecksum?: boolean;
  /**
   * If you want to call it from bulkservice for example,
   * you don't want to send the status update to the whole UI.
   */
  exclusiveProgress?: (s: SaveDocumentStatus) => void;
}

export interface UploadProgress
{
  speed: string;
  totalProgress: string;
  percentage: number;
}

export interface SaveDocumentStatus
{
  status: SaveDocumentState;
  details: string;
  overallProgress: number;
  document: MoonDeskDocument;
  mode: 'NewDoc'|'NewVersion';
  saveAndClose: boolean;
  currentUploadProgress: Progress;
  cancel: () => void;
}

export enum SaveDocumentState
{
  preparingupload,
  uploading,
  done,
  cancelled,
  error
}

export interface DocumentIdChangedEvent
{
  oldDocument: MoonDeskDocument;
  newDocument: MoonDeskDocument;
}

interface LinkedFileElement
{
  /**
   * the link inside the .ai file
   */
  link: string;
  /**
   * the real file (can be the link, or a file with the same
   * name in the subfolder Links)
   */
  linkedFile: string;
  parsed: ParsedPath;
  checksum: string;
  targetFile: string;
  documentVersion: DocumentVersion;
}

export interface LinkedFont
{
  name: string;
  filename?: string;
  path: string;
  checksum: string;
  documentVersion: DocumentVersion;
  // Used for 0kb fonts (PostScript or Type 1 format)
  formatNotSupported?: boolean;
}

/**
 * This service:
 * - Helps evaluating if a document/version can be saved
 * - Performs save operations (generation of output and upload)
 * - Informs about the status (just for WorkManagerService and SaveDocumentComponent)
 */
@Injectable({
  providedIn: 'root'
})
export class SaveDocumentService
{
  _status: SaveDocumentStatus;
  documentIdChanged: EventEmitter<DocumentIdChangedEvent> = new EventEmitter<DocumentIdChangedEvent>();

  constructor(
    private logger: LoggingService,
    private illService: IllustratorService,
    private filePath: FilepathService,
    private fileService: FilesystemService,
    private docService: DocumentService,
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private rulesExecutionService: RulesExecutionService,
    private ruleHelperService: RuleHelperService,
    private docSharingService: DocumentSharingService,
    private libraryCache: LibraryCacheService,
    private traslationService: TranslationService,
    private eventHubService: EventHubService)
  {
    console.log('SaveDocumentService');
    this.updateStatus(SaveDocumentState.done, null, 0, undefined, {mode: undefined});
  }

  /**
   * Should never be accessed from outside! It's public for the unit test
   */
  get status()
  {
    return this._status;
  }
  statusChange: EventEmitter<SaveDocumentStatus> = new EventEmitter<SaveDocumentStatus>();
  /**
   * Should never be accessed from outside! It's public for the unit test
   */
  updateStatus(state: SaveDocumentState,
              details: string,
              overallProgress: number,
              doc: MoonDeskDocument,
              options: SaveDocumentOptions,
              currentUploadProgress?: Progress)
  {
    if (this._status && this._status.status === SaveDocumentState.cancelled &&
        (state === SaveDocumentState.preparingupload || state === SaveDocumentState.uploading))
    {
      // this will lead straight to the catch/finally block of saveDocument()
      throw new Error('Cancelled');
    }

    const status: SaveDocumentStatus =
    {
      status: state,
      details: details,
      overallProgress: overallProgress,
      currentUploadProgress: currentUploadProgress,
      document: doc,
      mode: options.mode,
      saveAndClose: options.saveAndClose,
      cancel: () =>
      {
        if (this._status)
        {
          if (this._status.currentUploadProgress)
          {
            this._status.currentUploadProgress.cancelAction();
          }
          if (this._status.document)
          {
            this._status.document.cancelExport = true;
          }
          this._status.status = SaveDocumentState.cancelled;
        }
        else
        {
          this.logger.trackTrace('Can\'t cancel SaveDocument', Severity.Warning);
        }
      }
    };
    if (options.exclusiveProgress)
    {
      this._status = status;
      options.exclusiveProgress(status);
    }
    else
    {
      this._status = status;
      this.statusChange.emit(this._status);
    }
  }


  canSaveNewVersion(document: MoonDeskDocument): boolean
  {
    if (document && document.id
       && this._status.status !== SaveDocumentState.preparingupload
       && this._status.status !== SaveDocumentState.uploading)
    {
      return true;
    }
    return false;
  }

  canSaveNewDocument(document: MoonDeskDocument): boolean
  {
    if (document && this._status.status !== SaveDocumentState.preparingupload
      && this._status.status !== SaveDocumentState.uploading)
    {
      return true;
    }
    return false;
  }

  /**
   * Should never be accessed from outside! It's public for the unit test
   */
  classSelectionComplete(doc: MoonDeskDocument): boolean
  {
    if (doc && doc.documentType)
    {
      const classIds: string[] = [];
      const identity = this.authService.getCurrentIdentity();
      const docType = identity.company.documentTypes.find(d => d.id === doc.documentType.id);
      if (!docType)
      {
        console.log('aaaarrrrggghhh');
      }
      docType.classes.forEach(element => classIds.push(element.id));
      let classes = _.filter(identity.company.classes, cls => _.any(classIds, id => id === cls.id));
      classes = this.docService.getPlainClassList(classes);
      if (_.all(classes, cls => _.any(doc.classValues, cv => cv.classId === cls.id)))
      {
        return true;
      }
    }
    return false;
  }

  private allRequiredClassesHaveValues(document: MoonDeskDocument): boolean
  {
    const identity = this.authService.getCurrentIdentity();
    const docTypeId: string = document?.documentType?.id ?? document?.documentTypeId;
    const docTypeClasses = identity.company.documentTypes.find(dt => dt.id === docTypeId)?.classes;
    const docTypeHaveClasses = docTypeClasses && docTypeClasses.length > 0;
    if (!docTypeHaveClasses)
    {
      return true;
    }
    const plainClasses = this.docService.getPlainClassList(docTypeClasses);

    const requiredClassesIds: string[] = plainClasses
      .filter(cls => cls.requiredValue)
      .map(c => c.id);

    const docClassesIds: string[] = document.classValues.map(cv => cv.classId);

    return requiredClassesIds.every(requiredCls => docClassesIds.includes(requiredCls));
  }

  async saveNewDocument(filters: DocumentFilter, documentTags: DocumentTag[], documentPath: string, options: SaveDocumentOptions)
  {
    if (!filters.docTypeIds || filters.docTypeIds.length !== 1)
    {
      throw new Error('Filter with one docType needed');
    }

    const originalFileChecksum = await this.getDocumentChecksum(documentPath);

    if (options.checkChecksum)
    {
      const docVersionExists = await this.docService.isAnyDocumentVersionForChecksum(originalFileChecksum);
      if (docVersionExists)
      {
        throw new Error('Document already uploaded');
      }
    }

    const document = this.createDocumentObject(documentPath);
    const company = this.authService.getCurrentIdentity().company;
    const doctype = company.documentTypes.find(dt => dt.id === filters.docTypeIds[0]);
    document.documentType = doctype;
    document.documentTypeId = doctype.id;
    document.classValues = this.docService.mapClassSelector(filters.classSelections);

    const docItems = await this.illService.getVisibleItems(documentPath);
    const firstVersion = await this.createDocumentVersion(document, docItems, documentPath);
    firstVersion.versionNumber = 1;
    firstVersion.documentTags = documentTags;
    firstVersion.originalFileChecksum = originalFileChecksum;
    document.editingVersion = firstVersion;
    options.mode = 'NewDoc';

    await this.saveDocument(document, null, options, []);
    await this.illService.closeDocument(document.workingCopy, false);
  }

  createDocumentObject(documentPath: string): MoonDeskDocument
  {
    const currentCompany = this.authService.getCurrentIdentity().company;
    const parsed = this.fileService.parse(documentPath);
    if (!parsed.ext || parsed.ext === '')
    {
      parsed.ext = '.ai';
    }
    const document: MoonDeskDocument = {
      classValues: undefined,
      company: currentCompany,
      companyId: currentCompany.id,
      documentType: undefined,
      latestVersion: undefined,
      editingVersion: {documentTags: [], fieldValues: [], fileType: parsed.ext, metadata: new DocumentVersionMetadata()},
      workingCopy: documentPath,
      isPdf: parsed.ext.toLowerCase() === '.pdf'
    };
    return document;
  }

  async saveDocument(original: MoonDeskDocument, task: MoonTask, options: SaveDocumentOptions, relatedTasks: MoonTask[] = [])
  {
    const company = this.authService.getCurrentIdentity().company;

    if(company.isStorageUsageLimitReached)
    {
      this.feedbackService.notifyMessage(
        this.traslationService.getTranslation('lid.srv.StorageUsageLimitReached')
      );
      return;
    }

    const occupiedPercentage = company.occupiedStoragePercentage;
    if (occupiedPercentage >= 80)
    {
      const storageWarningMsg = this.traslationService.getTranslation('lid.ext.pages.work.save-document.storageUsageWarning',
      {
        occupiedPercentage: occupiedPercentage
      });
      this.feedbackService.notifyMessage(storageWarningMsg);
    }

    if (options.mode === 'NewDoc' ? !this.canSaveNewDocument(original) : !this.canSaveNewVersion(original))
    {
      this.feedbackService.notifyMessage('Can\'t save document');
      return;
    }
    if (options.suppressCompleteClassSelection !== true && !this.classSelectionComplete(original))
    {
      this.feedbackService.notifyMessage('Incomplete class selection');
      return;
    }
    else if (original.documentType === undefined)
    {
      this.feedbackService.notifyMessage('Missing document type');
      return;
    }

    if (options.mode === 'NewDoc' && !this.allRequiredClassesHaveValues(original))
    {
      this.feedbackService.notifyMessage('There are required classifiers with no assigned value');
      return;
    }

    if (this._status && this._status.status === SaveDocumentState.cancelled)
    {
      this._status.status = SaveDocumentState.preparingupload; // to reset the 'cancel' state and suppress the Error()
    }

    let document: MoonDeskDocument;
    let errorMsg: string;
    let missingFontsOrLinks: boolean;
    try
    {
      const currentCompany = this.authService.getCurrentIdentity().company;
      const parsed = this.fileService.parse(original.workingCopy);
      if (parsed.ext !== undefined && parsed.ext !== '' && parsed.ext.toLowerCase() !== '.ai')
      {
        this.feedbackService.notifyMessage(`${parsed.ext} not supported yet`);
        return;
      }
      /// PREPARE
      // copy doc
      this.updateStatus(SaveDocumentState.preparingupload, 'Analyzing document', 1, undefined, options);
      document = _.clone(original);
      if (!await this.illService.isDocumentOpen(document.workingCopy))
      {
        throw new Error(
          'The document could not be found. Please check that it does not have any "/" (slash) ' +
          'characters either in the name or in the folders containing it and reopen it.'
        );
      }

      /// PROCESS FILE
      this.updateStatus(SaveDocumentState.preparingupload, 'Reading document contents', 2, document, options);
      const docItems = await this.illService.getVisibleItems(document.workingCopy);
      const version = await this.createDocumentVersion(document, docItems, document.workingCopy);
      document.latestVersion = version;
      const compatibilityEnabled = currentCompany.configuration.saveAiWithCompatibility;

      let savePath: string;
      if (options.mode === 'NewDoc')
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Getting id', 3, document, options);
        document.id = await this.docService.getNewDocumentId();

        this.updateStatus(SaveDocumentState.preparingupload, 'Saving file', 4, document, options);
        document.latestVersion.fileType = '.ai';
        const freepath = await this.getFreeFilename(document);
        savePath = freepath;
      }
      else
      {
        savePath = document.workingCopy;
      }

      this.logger.trackTrace(`${options.mode === 'NewDoc' ? 'IMPORT' : 'EDIT'}: Saving file ${document.workingCopy} as ${savePath}`);

      await this.illService.saveAsDocument(document.workingCopy, savePath, compatibilityEnabled);

      this.illService.updateDocPathMappingWorkingCopy(document.workingCopy, savePath);
      document.workingCopy = savePath;
      original.workingCopy = savePath;

      /// CHECK SIZE
      const size = this.fileService.getFileSize(document.workingCopy);
      if (size > DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE)
      {
        this.feedbackService.notifyMessage(`Archive is too big (max ${DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE / 1000000}MB)`);
        options.saveAndClose = false;
        return;
      }

      if (!options.suppressRuleConfirmation)
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Checking rules...', 5, document, options);
        const ruleResults = await this.checkRules(document, document.workingCopy, options.suppressDuplicateRule, task);
        // if the user hits cancel during the checkRules, we don't want the results to appear, so we update the status again...
        // this.updateStatus(SaveDocumentState.preparingupload, 'Checking rules...', 4, document, options);
        // if (!options.suppressRuleConfirmation && !(await this.ruleService.showResultsDialog(ruleResults, true, document.workingCopy)))
        if (!ruleResults)
        {
          if (this._status)
          {
            this._status.cancel();
          }
          return;
        }
        document.latestVersion.ruleResults = ruleResults;
      }

      let lastVersion: DocumentVersion;

      if (options.mode === 'NewVersion')
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Checking existing documents', 6, document, options);
        lastVersion = await this.docService.getLatestVersion(document.id);
      }

      /// USER INPUT
      if (!options.suppressVersionCheck && options.mode === 'NewVersion')
      {
        const lastIsNewer = lastVersion.versionNumber > document.editingVersion.versionNumber
                          || (lastVersion.versionNumber === document.editingVersion.versionNumber &&
                              lastVersion.minorVersionNumber > document.editingVersion.minorVersionNumber);
        if (lastIsNewer)
        {
          const message = `${lastVersion.creator} already uploaded a newer version for this document\n
                        Do you want to upload anyway?`;
          if (!await this.askDialog('Please confirm', message))
          {
            if (this._status)
            {
              this._status.cancel();
            }
            return;
          }
        }
      }

      let shareBasket: ShareBasket;
      let documentPost: DocumentPost;

      let linkedFonts: LinkedFont[] = [];
      linkedFonts = await this.verifyAndGetFixedFonts(document);
      if (!linkedFonts)
      {
        this._status.cancel();
        return;
      }

      const missingLinks = await this.areMissingLinks(document.workingCopy);
      let missingFonts = [];
      if (currentCompany.configuration.fontsControl)
      {
        missingFonts = await this.illService.getDocMissingFonts(document.workingCopy);
      }

      missingFontsOrLinks = missingLinks || (missingFonts && missingFonts.length > 0);

      if (!options.suppressShareDialog)
      {
        const exportOptions = await this.docSharingService.getExportOptions();
        const saveDialogData: SaveDocumentPopupData =
        {
          currentTask: task,
          currentDocument: document,
          exportOptions: exportOptions,
          isNewDoc: options.mode === 'NewDoc',
          relatedTasks: relatedTasks,
          missingFontsOrLinks: missingFontsOrLinks
        };

        const saveDialogResult = await this.openSaveDialog(saveDialogData);
        if (!saveDialogResult)
        {
          if (this._status)
          {
            this._status.cancel();
          }
          return;
        }
        shareBasket = saveDialogResult.shareBasket;
        documentPost = saveDialogResult.documentPost;

        if (shareBasket?.exportOptions)
        {
          await this.docSharingService.setExportOptions(shareBasket.exportOptions);
        }
      }
      else
      {
        documentPost =
        {
          document: document,
          isMayorVersion: true,
          markApproved: false,
          taskActions: [],
          uploadedFromWeb: false
        };
      }

      // manage "linked" fonts
      let fontsVerIds = [];
      if (linkedFonts && linkedFonts.length > 0)
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Handling linked fonts', 9, document, options);
        try
        {
          fontsVerIds = await this.handleLinkedFonts(linkedFonts, document, options);
        }
        catch (err)
        {
          this.feedbackService.notifyError(`Error uploading fonts: ${err.message}`, err);
        }
      }
      // manage linked files
      this.updateStatus(SaveDocumentState.preparingupload, 'Handling linked files', 9, document, options);
      const linkVerIds = await this.handleLinkedFiles(document, options);

      document.latestVersion.childVersionIds = _.union(fontsVerIds, linkVerIds);

      // this service makes 'SaveAs' operations which could lead to corrupt data in work- and currentdocservice,
      // so we update the workingCopy of the original
      if (options.mode === 'NewVersion')
      {
        original.workingCopy = document.workingCopy;
      }

      this.updateStatus(SaveDocumentState.preparingupload, 'Saving document...', 10, document, options);
      let newVersionNumber: number;
      let newMinorVersionNumber: number;
      if (options.mode === 'NewDoc')
      {
        newVersionNumber = 1;
        newMinorVersionNumber = 0;
      }
      else if (documentPost.isMayorVersion)
      {
        newVersionNumber = lastVersion.versionNumber + 1;
        newMinorVersionNumber = 0;
      }
      else
      {
        newVersionNumber = lastVersion.versionNumber;
        newMinorVersionNumber = lastVersion.minorVersionNumber + 1;
      }
      await this.illService.setExtDocInfo(document.workingCopy, document.id, newVersionNumber, newMinorVersionNumber);

      await this.illService.saveDocument(document.workingCopy, compatibilityEnabled);

      let pdfFile: string|Buffer;


      if (shareBasket)
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Creating objects to share', 11, document, options);
        shareBasket = await this.docSharingService.createShareObjects(shareBasket, document);
      }

      if (shareBasket && shareBasket.exportFiles?.length > 0 &&
        shareBasket.exportFiles.map(ef => ef.name).includes(ExportFilesIdentifiers.PreviewPdf))
      {
        const previewPdfExport = shareBasket.exportFiles.find(ef => ef.name === ExportFilesIdentifiers.PreviewPdf);
        // We already generated the light/preview PDF for export, so no need to generate it again
        pdfFile = <Buffer>await this.fileService.readRawFileAsync(previewPdfExport.filePath);
      }
      else
      {
        this.updateStatus(SaveDocumentState.preparingupload, 'Creating previews...', 10, document, options);
        pdfFile = await this.libraryCache.createPdf(document.workingCopy);
      }

      document.latestVersion.originalFileChecksum = document.editingVersion?.originalFileChecksum;

      // UPLOAD
      // upload the document itself
      this.updateStatus(SaveDocumentState.uploading, 'Uploading', 12, document, options);
      document = await this.upload(documentPost,
                              document.workingCopy,
                              pdfFile,
                              options);
      if (shareBasket)
      {
        await this.docSharingService.handleShareObjects(shareBasket, document, (p) =>
        {
          // this.updateStatus(SaveDocumentState.uploading, text, percentage, document, options);
          this.updateStatus(SaveDocumentState.uploading, p.details, 13, document, options, p.currentProgress);
        });
        if (shareBasket.exportOptions.downloadFiles)
        {
          this.feedbackService.notifyMessage(`Opening your download folder ${shareBasket.localDownloadFolder}`);
        }
      }

      if (company.configuration.renameDocumentsOnImport)
      {
        try
        {
          const originalFile = this.illService.getDocOriginalPath(document.workingCopy);
          if (!originalFile)
          {
            return;
          }
          const filePathWithoutExtension = originalFile.substring(0, originalFile.lastIndexOf('.'));
          const fileExtension = originalFile.substring(originalFile.lastIndexOf('.'));
          savePath = filePathWithoutExtension + ` - ${document.moonNumber}` + fileExtension;
          this.fileService.renameFile(originalFile, savePath);
        }
        catch (err)
        {
          console.log(err);
          this.eventHubService.silentSupportMessage.emit(
            {message: `Error renaming document ${document.id} after save`, error: err});
        }
      }

      this.feedbackService.notifyMessage('Successfully saved document');
    }
    catch (err)
    {
      if (this._status && this._status.status !== SaveDocumentState.cancelled)
      {
        const msg = err.error ? err.error : err.message;
        errorMsg = msg ? `Error saving document: ${msg}` : 'Error saving document';
        this.feedbackService.notifyError(errorMsg, err);
      }
      else
      {
        this.logger.logException(err);
      }
    }
    finally
    {
      this.libraryCache.cleanTempFolder();
      if (errorMsg)
      {
        this.updateStatus(SaveDocumentState.error, errorMsg, 0, document, options);
      }
      else if (this._status && this._status.status === SaveDocumentState.cancelled)
      {
        this.logger.trackTrace('Cancelled by user');
        this.updateStatus(SaveDocumentState.cancelled, null, 0, undefined, options);
      }
      else
      {
        this.updateStatus(SaveDocumentState.done, undefined, 100, document, options);
        const successMsg = `EXT - SaveDocument - Successful - docId: ${document.id}`;
        this.logger.trackEvent(successMsg, null, {'MissingLinksOrFonts': missingFontsOrLinks ? 'true' : 'false'});
      }
      this.updateStatus(SaveDocumentState.done, null, 0, undefined, options);
    }
  }

  /**
   * @returns null if cancel, else return the fonts that are both in the document and on disk.
   * It also offers the user the possibility to search for fonts that we could not detect automatically (And add them to the result).
   * In addition, it filters out fonts of formats that we do not support (type 1 and postscript).
   */
  private async verifyAndGetFixedFonts(document: MoonDeskDocument): Promise<LinkedFont[]>
  {
    const result: LinkedFont[] = [];
    const brokenFonts: LinkedFont[] = [];
    const plataformFonts: PlatformFont[] = this.fileService.getPlatformFonts();
    const currentDocFonts = await this.illService.getFonts(document.workingCopy);

    const fontsInDiskAndDocument =
      _.filter(
        plataformFonts,
        plataformFont => _.any(currentDocFonts, currentDocFont => currentDocFont.diskFontName === plataformFont.name)
      );
    fontsInDiskAndDocument.forEach(plataformFont =>
    {
      const fontSize = this.fileService.getFileSize(plataformFont.filepath);
      const font: LinkedFont =
      {
        name: plataformFont.name,
        path: plataformFont.filepath,
        checksum: null,
        documentVersion: null
      };
      // Check for PostScript and Type 1 fonts
      if (fontSize === 0)
      {
        font.formatNotSupported = true;
        brokenFonts.push(font);
      }
      else
      {
        result.push(font);
      }
    });


    const missingFontInDisk = _.filter(currentDocFonts, font => !_.any(fontsInDiskAndDocument, pf => pf.name === font.diskFontName));
    // Remove all illustrator fonts (diskFontName without extensions)
    // missingFontInDisk = _.filter(missingFontInDisk, font => !font.diskFontName || this.fileService.parse(font.diskFontName).ext !== '');

    missingFontInDisk.forEach(font =>
    {
      brokenFonts.push({
        checksum: null,
        documentVersion: null,
        path: null,
        name: `${font.illFontName}`
      });
    });

    if (brokenFonts && brokenFonts.length > 0)
    {
      const fixedFonts = await this.openFontsHandlerDialog(brokenFonts);
      if (!fixedFonts)
      {
        return;
      }
      fixedFonts.forEach(f => result.push(f));
    }

    return result;
  }

  private async handleLinkedFonts(
    currentDocFonts: LinkedFont[],
    document: MoonDeskDocument,
    options: SaveDocumentOptions): Promise<string[]>
  {
    const company = this.authService.getCurrentIdentity().company;

    let linkedFonts: LinkedFont[] = [];

    if (currentDocFonts.length > 0)
    {
      // 1. read all fonts and check with backend if they are known (using checksum)
      for (const docFont of currentDocFonts)
      {
        try
        {
          const checksum = await this.fileService.fileChecksum(docFont.path);
          if (checksum && !_.any(linkedFonts, lf => lf.checksum === checksum))
          {
            linkedFonts.push({
              name: docFont.name,
              path: docFont.path,
              checksum: checksum,
              documentVersion: undefined
            });
          }
        }
        catch (err)
        {
          let failReason = err.message;
          if (failReason === '0 KB files are not allowed')
          {
            failReason = 'PostScript or Type 1 fonts are not supported';
          }
          this.feedbackService.notifyError(`Error uploading font "${docFont.name}": ${failReason}`, err);
        }
      }
    }

    const knownDocVersions =
      await this.docService.getDocumentVersionsForChecksums(company.id, _.map(linkedFonts, lf => lf.checksum));


    if (knownDocVersions.length > 0)
    {
      linkedFonts.forEach(lf => lf.documentVersion = _.find(knownDocVersions, known => known.checksum === lf.checksum));
    }


    // 2. create documents for each unknown file
    const notExisting = _.filter(linkedFonts, lf => !lf.documentVersion);
    const failedLinkedFonts: LinkedFont[] = [];
    for (const linkedFont of notExisting)
    {
      try
      {
        this.updateStatus(SaveDocumentState.preparingupload,
          `Uploading new linked font ${linkedFont.name}`, 9,
            document, options);
        const newLibElem = await this.libraryCache.createLibraryElement(linkedFont.path, (p) =>
        {
          this.updateStatus(SaveDocumentState.uploading,
          `Uploading ${linkedFont.name} to library`,
          9, document, options, p);
        });
        linkedFont.documentVersion = newLibElem.document.latestVersion;
      }
      catch (err)
      {
        this.feedbackService.notifyError(`Error uploading font "${linkedFont.name}": ${err.message}`, err);
        failedLinkedFonts.push(linkedFont);
      }
    }
    // 3. Remove all linked fonts that have failed to upload
    linkedFonts = _.filter(linkedFonts, lf => !_.any(failedLinkedFonts, flf => flf.checksum === lf.checksum));

    // 4. save the id's of the linked elements (documentversions) to the main document (childVersionIds)
    const linkedVersionIds = _.map(linkedFonts, lf => lf.documentVersion.id);

    return _.unique(linkedVersionIds, l => l);
  }

  private async areMissingLinks(filePath: string)
  {
    const localLinks: string[] = await this.illService.getLocalLinks(filePath);
    const emptyLinks: string[] = _.filter(localLinks, localLink => localLink === 'unknown');

    return emptyLinks && emptyLinks.length > 0;
  }

  /**
   * Looks for all linked elements of the ai document
   * If a file doesn't exist, throws an Error
   * If a file is bigger than 20MB, throws an Error
   * Checks if they are known docVersions in MoonDesk (using checksum)
   * If not, creates new
   * @param document
   */
  private async handleLinkedFiles(document: MoonDeskDocument, options: SaveDocumentOptions): Promise<string[]>
  {
    const company = this.authService.getCurrentIdentity().company;
    let result: string[] = [];

    this.logger.trackTrace(`&&&&&&&&&&&&&&&& START handleLinkedFiles(${document.workingCopy})`);
    const stopwatch = new Stopwatch();
    stopwatch.start();
    this.updateStatus(SaveDocumentState.preparingupload, 'Analysing linked elements', 9, document, options);
    let links = await this.illService.getLinkedFiles(document.workingCopy);

    links = _.filter(links, l => l !== 'unknown');
    this.logger.trackTrace(`Read ${links.length} linked file info from illustrator in ${stopwatch.slice().duration}`);
    console.log(links);
    if (links.length > 0)
    {
      const linkedElements: LinkedFileElement[] = [];
      // 1. read all linked files and check with backend if they are known (using checksum)
      const docPath = await this.filePath.getDocumentsFolder(document.id);
      const linkFolder = await this.filePath.join(docPath, 'Links');
      for (let i = 0; i < links.length; i++)
      {
        this.updateStatus(SaveDocumentState.preparingupload, `Analysing linked element ${i + 1} of ${links.length}`, 9, document, options);
        let missingLink: boolean;
        const linkedElement = {
          link: links[i],
          linkedFile: undefined,
          parsed: this.fileService.parse(links[i]),
          checksum: undefined,
          documentVersion: undefined,
          targetFile: undefined
        };
        if (this.fileService.exists(links[i]))
        {
          linkedElement.linkedFile = links[i];
        }
        else
        {
          const subfolderLink = this.fileService.join(linkFolder, linkedElement.parsed.base);
          if (this.fileService.exists(subfolderLink))
          {
            linkedElement.linkedFile = subfolderLink;
            linkedElement.parsed = this.fileService.parse(subfolderLink);
          }
          else
          {
            // throw new Error(`Linked file ${linkedElements[i].link} can't be found`);
            console.log((`Linked file ${linkedElement.link} can't be found`));
            missingLink = true;
          }
        }
        if (!missingLink)
        {
          linkedElements.push(linkedElement);
          linkedElements[i].checksum = await this.fileService.fileChecksum(links[i]);
        }
      } // end if analyzing

      if (linkedElements && linkedElements.length > 0)
      {
        this.logger.trackTrace(`CHK Read all checksums in ${stopwatch.slice().duration}`);
        this.updateStatus(SaveDocumentState.preparingupload, `Checking if library elements already exist`, 9, document, options);
        const knownDocVersions =
          await this.docService.getDocumentVersionsForChecksums(company.id, _.map(linkedElements, le => le.checksum));
        if (knownDocVersions.length > 0)
        {
          linkedElements.forEach(le => le.documentVersion = _.find(knownDocVersions, known => known.checksum === le.checksum));
        }
        this.logger.trackTrace(`Queried ${knownDocVersions.length} existing docVersions in ${stopwatch.slice().duration}`);

        // 2. create documents for each unknown file
        let notExisting = _.filter(linkedElements, l => !l.documentVersion);
        notExisting = _.unique(notExisting, n => n.linkedFile);
        for (let i = 0; i < notExisting.length; i++)
        {
          const size = this.fileService.getFileSize(notExisting[i].linkedFile);

          if (size > DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE)
          {
            throw new Error(`Linked file ${notExisting[i].linkedFile} is too big (max ${DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE / 1000000}MB)`);
          }

          try
          {
            this.updateStatus(SaveDocumentState.preparingupload,
                              `Uploading new linked image ${notExisting[i].parsed.base}`, 9,
                              document, options);
            const newLibElem = await this.libraryCache.createLibraryElement(notExisting[i].linkedFile, (p) =>
            {
              this.updateStatus(SaveDocumentState.uploading,
                    `Uploading ${notExisting[i].parsed.name}${notExisting[i].parsed.ext} to library`,
                    9, document, options, p);
            });

            notExisting[i].documentVersion = newLibElem.document.latestVersion;
            // if the new image (i) was added more than once, we also have to assign the docVersion to the other ones
            const others = _.filter(linkedElements, x => x.documentVersion === undefined && x.linkedFile === notExisting[i].linkedFile);
            for (const o of others)
            {
              o.documentVersion = notExisting[i].documentVersion;
            }
          }
          catch (err)
          {
            const errMsg = `The linked file "${notExisting[i].parsed.name}" could not be uploaded: ${err.message}`;
            throw new Error(errMsg);
          }

        }
        this.logger.trackTrace(`Created ${notExisting.length} new library elements in ${stopwatch.slice().duration}`);

        // 3. copy all files to our defined working folder
        let copyCounter = 0;
        for (let i = 0; i < linkedElements.length; i++)
        {
          this.updateStatus(SaveDocumentState.preparingupload, `Copying link ${i + 1}`, 9, document, options);
          const le = linkedElements[i];
          ++copyCounter;
          const targetFileName = `${le.documentVersion.id}${le.documentVersion.fileType}`;
          le.targetFile = this.fileService.join(linkFolder, targetFileName);
          await this.fileService.copyFile(le.linkedFile, le.targetFile);
        }
        this.logger.trackTrace(`Copied ${copyCounter} files to link folder in ${stopwatch.slice().duration}`);

        // 4. make sure the file is in our working directory, so the links can work
        const parsedDocument = this.fileService.parse(document.workingCopy);
        if (parsedDocument.dir !== docPath)
        {
          // copy the file to the documentsFolder
          const newFilePath = await this.getFreeFilename(document);
          const compatibilityEnabled = this.authService.getCurrentIdentity().company.configuration.saveAiWithCompatibility;
          await this.illService.saveAsDocument(document.workingCopy, newFilePath, compatibilityEnabled);
          document.workingCopy = newFilePath;
          this.logger.trackTrace(`Saved the document in our working folder in ${stopwatch.slice().duration}`);
        }

        // 5. adapt the links to point relatively to the Links folder
        this.updateStatus(SaveDocumentState.preparingupload, 'Updating links in document', 9, document, options);
        const linksToBeReplaced = _.filter(linkedElements, le => le.targetFile !== undefined);
        if (linksToBeReplaced.length > 0)
        {
          const keyValuePairs = _.map(linksToBeReplaced, le => <KeyValuePair>{key: le.link, value: le.targetFile});
          await this.illService.replaceLinks(document.workingCopy, keyValuePairs);
        }
        this.logger.trackTrace(`Updated ${linksToBeReplaced.length} links in ${stopwatch.slice().duration}`);

        // 6. save the id's of the linked elements (documentversions) to the main document (childVersionIds)
        const linkedVersionIds = _.map(linkedElements, le => le.documentVersion.id);
        result = _.unique(linkedVersionIds, l => l);
      }
    }
    stopwatch.stop();
    this.logger.trackTrace(`&&&&&&&&&&&&&&&& END handleLinkedFiles(${document.workingCopy}) after ${stopwatch.getTime()}`);
    return result;
  }

  private openSaveDialog(saveDialogData: SaveDocumentPopupData): Promise<SaveDocumentPopupResult>
  {
    return new Promise<SaveDocumentPopupResult>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(SaveDocumentPopupComponent, {
        data: <SaveDocumentPopupData>saveDialogData,
        // Todo - should be 'maxHeight' but it doesnt work. Global styles bug?
        // height: '80%',
        width: '80%',
        disableClose: true,
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  private openFontsHandlerDialog(brokenFonts: LinkedFont[]): Promise<LinkedFont[]>
  {
    return new Promise<LinkedFont[]>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(FontsHandlerDialogComponent, {
        data: brokenFonts,
        disableClose: true,
        autoFocus: false,
        width: '80%'
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  /**
   * Get a filename that is not open in illustrator!
   */
  async getFreeFilename(doc: MoonDeskDocument, openFiles?: string[]): Promise<string>
  {
    if (!openFiles)
    {
      openFiles = await this.illService.getOpenDocuments();
    }
    let filepath = await this.filePath.getWorkingCopyPath(doc, undefined, 'InWork', doc.editingVersion.fileType);
    let counter = 0;
    while (_.any(openFiles, of => of === filepath))
    {
      ++counter;
      filepath = await this.filePath.getWorkingCopyPath(doc, `_${counter}`, 'InWork', doc.editingVersion.fileType);
    }
    return filepath;
  }

    /**
   * Creates an instance of DocumentVersion
   * @param document The document for which to create a new version
   * @param illItems All the items of the illustrator document
   * @param documentPath the full file path to the document
   */
  async createDocumentVersion(document: MoonDeskDocument, illItems: IllustratorItem[], documentPath: string): Promise<DocumentVersion>
  {
    this.logger.trackTrace('createDocumentVersion');
    const fieldTypes = _.find(this.authService.getCurrentIdentity().company.documentTypes,
                                                docType => docType.id === document.documentType.id).fieldTypes
                      .filter(ft => ft.type !== 'Barcode');

    // we are just interested in those illustrator items which are not empty
    const relevantItems = _.filter(illItems, ii => ii.content !== undefined && ii.content !== '');
    // get the fieldType for each fieldValue
    const fieldValues = _.map(relevantItems, item =>
    {
      const ft = _.find(fieldTypes, f => f.code === item.code);
      const assgnmt: FieldValue = {
        fieldType: ft,
        value: item.content,
      };
      return assgnmt;
    });

    // var barcodeType = _.find(fieldTypes, dt => dt.type == 'Barcode');
    // if(barcodeType)
    // {
    //   var documentString = <string>await this.fileService.readFileAsync(imageFilePath, {encoding: "base64"});
    //   var stopwatch = new Stopwatch();
    //   stopwatch.start();
    //   var barcodes = await this.dynamsoftBarcodeService.readBarcodes(documentString);
    //   stopwatch.stop();
    //   this.logger.trackEvent(`Getting barcode from document image took ${stopwatch.getTime()}ms`);
    //   fieldValues.push({
    //     fieldType: barcodeType,
    //     value: barcodes.join(' | ')
    //   });
    // }

    // we just save those that have a fieldType associated
    const typedValues = _.filter(fieldValues, f => f.fieldType !== undefined);

    // additionally, we create a big long string to save in Db
    const fullText = this.getFullText(fieldValues);
    const ext = this.fileService.parse(documentPath).ext;
    const newDocumentVersion: DocumentVersion = {
      documentTags: document.editingVersion.documentTags,
      fileType: ext,
      fieldValues: typedValues,
      fullText: fullText,
      metadata: new DocumentVersionMetadata()
    };

    return newDocumentVersion;
  }


  async fixLinkedFiles(docPath: string, linkReplacements: LinkReplacement[])
  {
    if (!linkReplacements || linkReplacements.length === 0)
    {
      return;
    }
    console.log('FIX LINK');
    const existingLinks = await this.illService.getLinkedFiles(docPath);
    // console.log(links);
    const keyValuePairs: KeyValuePair[] = [];
    if (existingLinks && existingLinks.length > 0)
    {
      const folder = this.fileService.parse(docPath).dir;
      const linkFolder = await this.fileService.join(folder, 'Links');
      // the correct linked files were already recovered on the disk, we just
      // have to correct the links
      const linkFiles = this.fileService.readdirSync(linkFolder, true, 'files');
      for (const replacement of linkReplacements)
      {
        const currentLink = _.find(existingLinks, f => f.indexOf(replacement.item1) > 0);
        const newfile = _.find(linkFiles, f => f.indexOf(replacement.item2) > 0);
        console.log(' replacement + ' + replacement.item1 + 'xxxxxxxxxxxxx' + currentLink + 'xxxxxxxxxxxx' + newfile);
        if (currentLink && newfile)
        {
          keyValuePairs.push({key: currentLink, value: newfile});
        }
      }
    }
    if (keyValuePairs.length > 0)
    {
      console.log(`replacing ${keyValuePairs.length} links...`);
      await this.illService.replaceLinks(docPath, keyValuePairs);
    }
    if (_.any(existingLinks, l => l === 'unknown'))
    {
      this.feedbackService.notifyMessage('Please add broken links from MoonDesk library');
    }
  }

  private getFullText(fieldValues: FieldValue[]): string
  {
    const notEmptyValues = _.filter(fieldValues, f => f.value.match(/\S/) != null);
    const fullText = _.map(notEmptyValues, v => v.value).join(' ');
    return fullText;
  }

  private async checkRules(document: MoonDeskDocument, documentPath: string,
            suppressDuplicateRule: boolean = false, task?: MoonTask): Promise<RuleResult[]>
  {
    this.logger.trackTrace(`Checking rules`);

    const docItems = await this.illService.getVisibleItems(documentPath, 0);
    const versionToControl = await this.createDocumentVersion(document, docItems, documentPath);

    const results = await this.ruleHelperService.runRulesAndShowResultsDialog(
      document, versionToControl, documentPath, suppressDuplicateRule, false, true, task);
    return results;
  }

  private async upload(
    documentPost: DocumentPost,
    documentPath: string,
    pdfFile: string|Buffer,
    options: SaveDocumentOptions): Promise<MoonDeskDocument>
  {
    console.log(`Reading files from disk`);
    const document = documentPost.document;
    this.updateStatus(SaveDocumentState.uploading, 'Reading files', 12, document, options, undefined);
    // await this.illService.saveDocument2(documentPath);
    let parsed = this.fileService.parse(documentPath);
    const docContent: Buffer = await this.fileService.readRawFileAsync(documentPath);
    const docVersionMetadata = this.docService.getPdfMetadata(docContent.toString());
    document.latestVersion.metadata = docVersionMetadata;
    this.updateStatus(SaveDocumentState.uploading, 'Checking checksum before upload', 12, document, options, undefined);

    document.latestVersion.checksum = await this.getDocumentChecksum(documentPath);
    console.log(`CHK New checksum for document ${document.id}: ${document.latestVersion.checksum}`);

    this.updateStatus(SaveDocumentState.uploading, 'Starting upload', 12, document, options, undefined);
    console.log(`Uploading document...`);
    parsed = this.fileService.parse(documentPath);
    const archive: DocumentRawData =
    {
      fileType: parsed.ext,
      fileContent: <Buffer>docContent
    };
    const l = await this.docService.postDocumentRaw(documentPost,
                                                <Buffer>pdfFile,
                                                archive,
                                                (p) =>
                                                this.updateStatus(SaveDocumentState.uploading, 'Uploading', 12, document, options, p));
    l.workingCopy = documentPath;
    l.editingVersion = l.latestVersion;
    return l;
  }

  private askDialog(title: string, question: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          title: title,
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'yes'));
    });
  }

  private async getDocumentChecksum(documentPath: string): Promise<string>
  {
    const result = await this.fileService.fileChecksum(documentPath);
    return result;
  }
}
