import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MoonDeskDocument } from '../_models/document/Document';
import { DocumentVersion, ExportFilesIdentifiers } from '../_models/document/DocumentVersion';
import { AuthService, Progress } from './auth.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { PDFDocument, PDFEmbeddedPage, PageSizes } from 'pdf-lib';
import * as _ from 'underscore';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { Class, ClassValue } from '../_models/configuration/Class';
import { DocumentChangedEvent } from '../_models/document/DocumentChangedEvent';
import { DocumentClassValuesChange } from '../_models/document/DocumentClassValuesChange';
import { ChecksumQuery, ClassSelection, DocumentFilter } from '../_models/document/DocumentFilter';
import { DocumentPost } from '../_models/document/DocumentPost';
import { DocumentRelation } from '../_models/document/DocumentRelation';
import { DocumentTag } from '../_models/document/DocumentTag';
import { DocumentVersionMetadata } from '../_models/document/DocumentVersionMetadata';
import { DocumentsResponse } from '../_models/document/DocumentsResponse';
import { FieldValue } from '../_models/document/FieldValue';
import { RequestSendDocuments, SentDocument } from '../_models/document/RequestSendDocument';
import { RuleResult } from '../_models/rules/RuleResult';
import { FileService } from './file.service';
import { TranslationService } from './translation.service';
import { PagedResponse } from '../_models/api/PagedResponse';
import { DocumentForExtensionDto } from '../_modelsDTO/documents/DocumentForExtensionDto';
import { LibraryDocForExtensionDto } from '../_modelsDTO/documents/LibraryDocForExtensionDto';
import { DocumentForWebDto } from '../_modelsDTO/documents/DocumentForWebDto';
import { DocumentTagChange } from '../public_api';


export interface DocumentData
{
  // .jpg, .png, .ai, ...
  fileType: string;
  fileContent: string;
}
export interface DocumentRawData
{
  // .jpg, .png, .ai, ...
  fileType: string;
  fileContent: ArrayBuffer;
}

interface ChangeClassValuesBulkPost
{
  documentIds: string[];
  documentFilter: DocumentFilter;
  overwriteAll: boolean;
}

export interface ExportFile
{
  name: ExportFilesIdentifiers;
  /**
   * Only if name === ExportFilesIdentifiers.Export_CustomPackage
   */
  customPackageExtension?: string;
  content: string | ArrayBuffer;

  // temp path for processing during DownloadDocService...
  filePath?: string;
  forDownload?: boolean;
  forUpload?: boolean;
}

interface DownloadZippedDocsPost
{
  versionIds: string[];
  fileTypes: string[];
  attachments?: string[];
  shareTaskNumber?: number;
  timezoneOffset: number;
}

interface DownloadDocReportPost
{
  documentFilter: DocumentFilter;
  timezoneOffset: number;
}

interface DocumentVersionMetadataPost
{
  documentVersionId: string;
  metadata: DocumentVersionMetadata;
}

export interface DocumentVersionName
{
  documentVersionId: string;
  name: string;
}

/**
 * Size is in bytes
 */
export const DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE = 1000000000; // 950MB

@Injectable({
  providedIn: 'root'
})
export class DocumentService
{
  documentChanged: EventEmitter<DocumentChangedEvent> = new EventEmitter<DocumentChangedEvent>();
  emitDocumentChanged(
    action: 'AddEdit' | 'Delete' | 'VersionState',
    documents: MoonDeskDocument[],
    version: DocumentVersion,
    forceSearch: boolean)
  {
    const event: DocumentChangedEvent = {
      action: action,
      documents: documents,
      version: version,
      forceSearch: forceSearch
    };
    this.documentChanged.emit(event);
  }

  constructor(
    private http: HttpClient,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private authService: AuthService,
    private traslationService: TranslationService,
    private fileService: FileService)
  { }

  async getFullImageUrl(docVersion: DocumentVersion, page: number = 0): Promise<string>
  {
    if(page > 1)
    {
      return await this.getFileUrl(docVersion, `fullPageImage.${page}.png`);
    }
    else
    {
      return await this.getFileUrl(docVersion, 'fullImage.png');
    }
  }

  async getDocument(id: string): Promise<MoonDeskDocument>
  {
    const params: [string, string][] = [['documentId', id]];
    try
    {
      const result: MoonDeskDocument = await this.authService.authGet<MoonDeskDocument>('/api/documents/getdocument', params);
      // We don't want recursion for transfer, but within the app we need the chain:
      if (result && result.latestVersion)
      {
        this.fixTimestampsAndAddUrl([result.latestVersion]);
        result.latestVersion.document = result;
      }
      return result;
    }
    catch (err)
    {
      return null;
    }
  }

  /**
   * Get all versions of a document
   * @param documentId The id of the document
   * @param taskId Optional if it's for a certain task (for 'Share' tasks, you only get the assigned version)
   */
  async getDocumentVersions(documentId: string, taskId?: string): Promise<DocumentVersion[]>
  {
    const params: [string, string][] = [
      ['documentId', documentId],
      ['taskId', taskId]
    ];
    const result = await this.authService.authGet<DocumentVersion[]>('/api/documents/getdocumentversions', params);
    this.fixTimestampsAndAddUrl(result);
    return result;
  }

  async getDocumentsByVersion(versionIds: string[]): Promise<MoonDeskDocument[]>
  {
    const result = await this.authService.authPost<MoonDeskDocument[]>('/api/documents/getDocumentsByVersion', versionIds);
    return result;
  }

  downloadFile(docVersion: DocumentVersion, progressCallback?: (numbPercentage: Progress) => void): Promise<any>
  {
    return new Promise<any>(async (resolve, reject) =>
    {
      // const progressCallbackInt = progressCallback;
      const downloadUrl = await this.getFileUrl(docVersion);
      const downloadReq = new HttpRequest('GET', downloadUrl, undefined, {
        reportProgress: true,
        responseType: 'arraybuffer'
      });
      this.authService.longRequest(downloadReq, resolve, reject, progressCallback);
    });
  }

  downloadFileWithUrl(url: string, progressCallback?: (numbPercentage: Progress) => void): Promise<any>
  {
    return new Promise<any>(async (resolve, reject) =>
    {
      // const progressCallbackInt = progressCallback;
      const downloadReq = new HttpRequest('GET', url, undefined, {
        reportProgress: true,
        responseType: 'arraybuffer'
      });
      this.authService.longRequest(downloadReq, resolve, reject, progressCallback);
    });
  }

  async downloadZippedDocsWeb(versionIds: string[], exportFiles: ExportFilesIdentifiers[], attachments?: string[], shareTaskNumber?: number)
  {
    const fileTypes: string[] = [];
    exportFiles.forEach(exportFile =>
    {
      fileTypes.push(exportFile);
    });
    const formData: DownloadZippedDocsPost =
    {
      versionIds: versionIds,
      fileTypes: fileTypes,
      attachments: attachments,
      shareTaskNumber: shareTaskNumber,
      timezoneOffset: new Date().getTimezoneOffset()
    };
    const url = await this.authService.authPost<string>('/api/documents/downloadZippedDocs', formData);
    this.authService.webDownload(url);
  }

  /**
   * Get all PDFs of the documents,
   * and place them in the smallest possible PDF page where they all fit,
   * going from A10 to A0, in case they do not fit in A0, an error is thrown.
   */
  async downloadDocumentsPdfs(documents: MoonDeskDocument[], filename?: string)
  {
    if (!documents || documents.length === 0)
    {
      console.log('Invalid function params: No documents!');
      throw new Error('Error downloading PDF');
    }
    const resultPdf = await PDFDocument.create();

    const inputPdfsEmbeddedPages: PDFEmbeddedPage[] = [];
    let inputPdfWidthSum: number = 0;

    for (const doc of documents)
    {
      const pdfUrl = await this.getFileUrl(doc.latestVersion, ExportFilesIdentifiers.PreviewPdf);
      await this.getPdfDocument(pdfUrl).then(async (docPdf) =>
      {
        const originalDocPdfEmbeded = await resultPdf.embedPage(docPdf.getPages()[0]);
        inputPdfWidthSum += originalDocPdfEmbeded.width;
        inputPdfsEmbeddedPages.push(originalDocPdfEmbeded);
      });
    }

    let pageASize = 10; // Maximum is 10 - Minimun is 0
    let pageSize: [number, number] = PageSizes[`A${pageASize}`];
    let scaleFactor: number = 0;

    while(scaleFactor < 0.9 && pageASize >= 0)
    {
      const resultPdfWidth = pageSize[1];
      scaleFactor = (resultPdfWidth) / (inputPdfWidthSum);
      if (scaleFactor < 1)
      {
        pageASize--;
        if (pageASize < 0)
        {
          throw new Error('The documents are too big');
        }
        pageSize = PageSizes[`A${pageASize}`];
      }
    }

    console.log(`Result will be A${pageASize} size`);
    console.log(pageSize);
    console.log(scaleFactor);

    const page = resultPdf.addPage([pageSize[1], pageSize[0]]);
    const resultPdfWidth: number = page.getWidth();
    let embeddedPdfTotalWidth: number = 0;

    inputPdfsEmbeddedPages.forEach(embeddedPdf =>
    {
      const scaledPdfDim = embeddedPdf.scale(scaleFactor);
      embeddedPdfTotalWidth += scaledPdfDim.width;
    });

    const marginBetweenDocs = inputPdfsEmbeddedPages.length === 1 ?
      (resultPdfWidth - embeddedPdfTotalWidth) :
      (resultPdfWidth - embeddedPdfTotalWidth) / (inputPdfsEmbeddedPages.length - 1);

    let embeddedPdfWidthSum: number = 0;
    for (let i = 0; i < inputPdfsEmbeddedPages.length; i++)
    {
      const embeddedPdf = inputPdfsEmbeddedPages[i];
      const scaledPdfDim = embeddedPdf.scale(scaleFactor);
      page.drawPage(embeddedPdf, {
        ...scaledPdfDim,
        x: embeddedPdfWidthSum + (marginBetweenDocs * i),
        y: (page.getHeight() - scaledPdfDim.height) / 2,
      });
      embeddedPdfWidthSum += scaledPdfDim.width;
    }

    const pdfData = await resultPdf.save();
    if (!pdfData)
    {
      return;
    }

    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename ? filename : `${documents[0].moonNumber} - Linked.pdf`;
    link.click();
  }

  async isPdfPasswordProtected(pdfFile: File): Promise<boolean>
  {
    const existingPdfBytes = await pdfFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
    return pdfDoc.isEncrypted;
  }

  /**
   * Get a PDFDocument object (from pdf-lib package) from a pdfUrl
   */
  private async getPdfDocument(pdfUrl: string): Promise<PDFDocument>
  {
    const pdfResponse = await fetch(pdfUrl);
    const existingPdfBytes = await pdfResponse.arrayBuffer();

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    return pdfDoc;
  }


  async getFileUrlWithZipVerification(docVersionId: string, fileName?: string, skipFriendlyName?: boolean): Promise<string>
  {
    if (fileName === ExportFilesIdentifiers.Export_PackageZip)
    {
      await this.generateAiZip(docVersionId);
    }
    if (fileName === ExportFilesIdentifiers.Export_OutlineAi)
    {
      await this.generateAiZip(docVersionId, true);
      fileName = ExportFilesIdentifiers.Export_OutlineZip;
    }
    return this.getSpecificFileUrl(docVersionId, fileName, skipFriendlyName);
  }

  async getFileUrl(docVersion: DocumentVersion, fileName?: string, skipFriendlyName?: boolean): Promise<string>
  {
    if (!fileName)
    {
      fileName = `document${docVersion.fileType}`;
    }
    return await this.getSpecificFileUrl(docVersion.id, fileName, skipFriendlyName);
  }

  async getSpecificFileUrl(docVersionId: string, fileName: string, skipFriendlyName?: boolean): Promise<string>
  {
    const params: [string, string][] =
    [
      ['docVersionId', docVersionId],
      ['fileName', fileName],
      ['skipFriendlyName', skipFriendlyName ? 'true' : 'false']
    ];
    const url = await this.authService.authGet<string>('/api/documents/getfileurl', params);
    return url;
  }

  getDocumentListDownloadUrl(lineFormat: string): string
  {
    if (!lineFormat)
    {
      throw new Error('No line format');
    }
    const identity = this.authService.getCurrentIdentity();
    if (!identity.user)
    {
      throw new Error('No token');
    }
    if (!identity.company)
    {
      throw new Error('No company');
    }
    const token = identity.user.token;
    const backendUrl = this.platformInfoProvider.getBackendUrl();
    const url =
    `${backendUrl}/api/documents/downloadDocumentList?companyId=${identity.company.id}&lineFormat=${lineFormat}&access_token=${token}`;
    return url;
  }

  async removeDocument(docId: string): Promise<void>
  {
    const params: [string, string][] = [['documentId', docId]];
    await this.authService.authDelete<void>('/api/documents/removeDocument', params);
    this.emitDocumentChanged('Delete', [], undefined, false);
  }

  async removeDocuments(docs: MoonDeskDocument[]): Promise<void>
  {
    const ids = _.map(docs, d => d.id);
    await this.authService.authPost<void>('/api/documents/removeDocuments', ids);
    this.emitDocumentChanged('Delete', docs, undefined, false);
  }

  async restoreDocument(documentId: string): Promise<void>
  {
    const params: [string, string][] = [['documentId', documentId]];
    await this.authService.authGet<void>('/api/documents/restoreDocument', params);

  }

  async getNewDocumentId(isLibraryType?: boolean): Promise<string>
  {
    const companyId = this.authService.getCurrentIdentity().company.id;
    const params: [string, string][] = [
      ['companyId', companyId],
      ['isLibraryType', isLibraryType ? 'true' : 'false']
    ];
    const result = await this.authService.authGet<string>('/api/documents/newDocumentId', params);
    return result;
  }

  async getLatestVersion(documentId: string): Promise<DocumentVersion>
  {
    const params: [string, string][] = [['documentId', documentId]];
    const result = await this.authService.authGet<DocumentVersion>('/api/documents/getlatestversion', params);
    return result;
  }

  async postDocumentRaw(documentPost: DocumentPost, pdfPreview: Buffer, archive: DocumentRawData,
    progressCallback: (progress: Progress) => void): Promise<MoonDeskDocument>
  {
    if (this.getRawBlob(archive.fileContent).size > DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE)
    {
      throw new Error(`The document exceeds the maximum allowed file size of ${DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE / 1000000} MB`);
    }
    const identiy = this.authService.getCurrentIdentity();
    if (identiy.company.isStorageUsageLimitReached)
    {
      throw new Error(
        this.traslationService.getTranslation('srv.lid.storageLimitReached') +
        this.traslationService.getTranslation('srv.lid.storageLimitReachedWarning')
      );
    }
    const formData = new FormData();
    let results: RuleResult[] = [];
    const doc = documentPost.document;
    if (doc.latestVersion.ruleResults && doc.latestVersion.ruleResults.length > 0)
    {
      results = doc.latestVersion.ruleResults.map(r =>
        ({
          applied: r.applied,
          error: r.error,
          data: null,
          description: r.description,
          configInfo: r.configInfo,
          failCause: r.failCause,
          isFromTask: r.isFromTask,
          content: r.content,
          rule: {
            id: r.rule.id,
            name: r.rule.name,
            runRule: undefined,
            action: undefined,
            parseConfig: undefined,
            actionName: r.rule.actionName
          },
          filePath: r.filePath,
          ruleConfigId: r.ruleConfigId,
          documentContentId: r.documentContentId,
          documentVersionId: r.documentVersionId,
          subTaskId: r.subTaskId,
          status: r.status,
          textContentResults: r.textContentResults,
          libraryContentResults: r.libraryContentResults
        }));
    }

    /**
     * Creating object with the exact info needed by backend
     */
    const sendDoc: MoonDeskDocument =
    {
      id: doc.id,
      company: {id: doc.company ? doc.company.id : doc.companyId },
      name: doc.name,
      latestVersion:
      {
        documentTags: _.map(doc.latestVersion.documentTags, t => <any>{value: t.value}),
        ruleResults: results,
        fieldValues: doc.latestVersion.fieldValues
                      ? _.map(doc.latestVersion.fieldValues, fv => <FieldValue>{value: fv.value, fieldType: {id: fv.fieldType.id}})
                      : [],
        fullText: doc.latestVersion.fullText,
        checksum: doc.latestVersion.checksum,
        originalFileChecksum: doc.latestVersion.originalFileChecksum,
        status: doc.latestVersion.status,
        childVersionIds: doc.latestVersion.childVersionIds ? _.clone(doc.latestVersion.childVersionIds) : [],
        fileType: doc.latestVersion.fileType,
        metadata: doc.latestVersion.metadata
      },
      documentType: doc.documentType ? <any>{id: doc.documentType.id, isLibraryType: doc.documentType.isLibraryType} : null,
      documentTypeId: doc.documentTypeId,
      classValues: doc.classValues ? _.map(doc.classValues, cv => <any>{id: cv.id, classId: cv.classId}) : [],
    };
    console.log('Adding document to form...');
    console.log(sendDoc);
    documentPost.document = sendDoc;
    formData.append('documentPost', JSON.stringify(documentPost));

    formData.append('file', this.getRawBlob(archive.fileContent), `document${archive.fileType}`);
    if (pdfPreview)
    {
      formData.append('file', this.getRawBlob(pdfPreview), 'preview.pdf');
    }

    const result = await this.authService.longPost<MoonDeskDocument>(`/api/documents/postRawDocumentForm`,
      formData, perc => progressCallback(perc));
    this.emitDocumentChanged('AddEdit', [result], undefined, true);
    return result;
  }

  async saveRawExportFilesForm(documentVersionId: string, exportFiles: ExportFile[], progressCallback: (progress: Progress) => void)
  {
    // To avoid overloading the server, we upload documents one at a time
    for (const ef of exportFiles)
    {
      const formData = new FormData();
      formData.append('documentVersionId', documentVersionId);

      const blob = this.getRawBlob(<ArrayBuffer>ef.content);
      if (blob.size > DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE)
      {
        throw Error(
          `Error uploading the file "${ef.name}" since the file size (${(blob.size / 1000000).toFixed(2)}MB)\
           exceeds the maximum allowed of ${DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE / 1000000} MB`);
      }

      let filename: string = ef.name;
      if (ef.name === ExportFilesIdentifiers.Export_CustomPackage && ef.customPackageExtension)
      {
        filename += ef.customPackageExtension;
      }
      formData.append('file', blob, filename);

      await this.authService.longPost<MoonDeskDocument>(
        `/api/documents/saveRawExportFilesForm`,
        formData,
        perc => progressCallback(perc));
    }
  }

  /**
   * It will be generated only if it does not exist for the current version
   */
  async generateAiZip(documentVersionId: string, outlineAi: boolean = false): Promise<boolean>
  {
    const params: [string, any][] = [
      ['versionId', documentVersionId],
      ['outlineAi', outlineAi]
    ];
    const result = await this.authService.authGet<boolean>('/api/documents/generateAiZipFile', params);
    return result;
  }

  async getDocumentReportHtml(docId: string): Promise<string>
  {
    const timezoneOffset = new Date().getTimezoneOffset();
    const params: [string, string][] = [
      ['docId', docId],
      ['timezoneOffset', `${timezoneOffset}`]
    ];
    const result = await this.authService.authGet<string>('/api/documents/getDocumentReportHtml', params);
    return result;
  }

  private getRawBlob(fileContent: ArrayBuffer): Blob
  {
    const result = new Blob([fileContent]);
    return result;
  }

  private getBlob(fileContent: string): Blob
  {
    const ia = new Uint8Array(fileContent.length);
    for (let i = 0; i < fileContent.length; i++)
    {
      ia[i] = fileContent.charCodeAt(i);
    }
    return new Blob([ia]);
  }

  async updateDocVersionState(version: DocumentVersion): Promise<DocumentVersion>
  {
    let obj: DocumentVersion =
    {
      id: version.id,
      documentId: version.document ? version.document.id : version.documentId,
      fileType: version.fileType,
      documentTags: _.clone(version.documentTags),
      status: version.status,
      fieldValues: _.clone(version.fieldValues),
      metadata: _.clone(version.metadata)
    };
    obj = await this.authService.authPost<DocumentVersion>('/api/documents/updateDocVersionState', obj);
    this.fixTimestampsAndAddUrl([obj]);
    this.emitDocumentChanged('VersionState', undefined, obj, false);
    return obj;
  }

  async changeClassValues(doc: MoonDeskDocument, filter: DocumentFilter): Promise<MoonDeskDocument>
  {
    let classValues = this.mapClassSelector(filter.classSelections);
    classValues = classValues.filter(c => c.id);
    const sendDoc: MoonDeskDocument = {
      id: doc.id,
      company: doc.company,
      companyId: doc.companyId,
      latestVersion: undefined,
      documentType: {id: filter.docTypeIds[0], companyId: null, name: null, classes: [], fieldTypes: []},
      classValues: classValues
    };
    const newDoc = await this.authService.authPost<MoonDeskDocument>('/api/documents/changeClassValues', sendDoc);
    this.emitDocumentChanged('AddEdit', [newDoc], undefined, false);
    return newDoc;
  }

  /**
   * To create a list of ClassValues from the selections of the filter object.
   */
  mapClassSelector(selections: ClassSelection[]): ClassValue[]
  {
    const result: ClassValue[] = [];
    const companyClasses = this.authService.getCurrentIdentity().company.classes;
    for (const selection of selections)
    {
      const classValues: ClassValue[] = _.find(companyClasses, cls => cls.id === selection.classId).classValues;
      for (const clsValueId of selection.classValueIds)
      {
        const currentClassValue: ClassValue = _.find(classValues, clsValue => clsValue.id === clsValueId);
        const classValue: ClassValue =
        {
          classId: selection.classId,
          id: clsValueId,
          name: currentClassValue.name,
          code: currentClassValue.code,
          class: null
        };
        result.push(classValue);
      }
    }
    return result;
  }

  getPlainClassList (clss: Class[]): Class[]
  {
    const result: Class[] = [];
    for (const cls of clss)
    {
      result.push(cls);
      if (cls.children)
      {
        const plainChilds = this.getPlainClassList(cls.children);
        result.push(...plainChilds);
      }
    }
    return result;
  }

  getPlainClassValuesList (clss: Class[]): ClassValue[]
  {
    const result: ClassValue[] = [];
    clss.forEach(cls =>
    {
      cls.classValues.forEach(cv =>
      {
        cv.class =
        {
          id: cls.id,
          name: cls.name,
          company: cls.company
        };
        result.push(cv);
      });
    });
    return result;
  }

  /**
   * To change the classValues of many documents
   * @param documentIds list of doc ids
   * @param filter the filter containing the selected classValues (no change of document type allowed)
   * @param overwriteAll true to replace all classValues of the documents. false to just add new classValues to the documents
   */
  async changeClassValuesBulk(documentIds: string[], filter: DocumentFilter, overwriteAll: boolean)
  {
    const post: ChangeClassValuesBulkPost =
    {
      documentIds: documentIds,
      documentFilter: filter,
      overwriteAll: overwriteAll
    };
    await this.authService.authPost<MoonDeskDocument[]>('/api/documents/changeClassValuesBulk', post);

    const documents: MoonDeskDocument[] = _.map(documentIds, docId =>
    {
      return {
        id: docId,
        company: undefined,
        friendlyName: undefined,
        documentType: {companyId: undefined, name: undefined, classes: undefined, fieldTypes: undefined},
        classValues: undefined,
        latestVersion: undefined,
        latestVersionNumber: undefined,
        latestMinorVersionNumber: undefined
      };
    });

    this.emitDocumentChanged('AddEdit', documents, undefined, true);
  }

  async searchDocuments(pfilter: DocumentFilter): Promise<DocumentsResponse>
  {
    const filter = pfilter;
    const data = await this.authService.authPost<MoonDeskDocument[]>('/api/documents/searchDocuments', filter);
    const result: DocumentsResponse = {
      filter: filter,
      result: data
    };
    result.result.forEach(async l =>
    {
      this.fixTimestampsAndAddUrl([l.latestVersion]);
    });
    return result;
  }

/**
 * Retrieves the names of documents that match the specified classification exactly,
 * @param documentTypeId The ID of the document type to search for.
 * @param classValuesIds An optional array of class values. If provided, only documents
 *                       that belong to the specified document type and exactly match the given
 *                       class values will be returned. If not provided or empty, only
 *                       documents belonging to the specified document type will be retrieved
 *                       (without considering any classification).
 * @returns A promise that resolves to an array of document names based on the workspace configuration.
 */
  async getDocumentsNamesByClassification(documentTypeId: string, classValuesIds: string[] = []): Promise<string[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] = [
      ['companyId', identity.company.id],
      ['documentTypeId', documentTypeId]
    ];
    classValuesIds?.forEach(cvId =>
    {
      params.push(['classValuesIds', cvId]);
    });

    const result = await this.authService.authGet<string[]>('/api/documents/getDocumentsNamesByClassification', params);
    return result;
  }

  async getDocumentsNamesWithSameClassification(documentId: string): Promise<string[]>
  {
    const identity = this.authService.getCurrentIdentity();
    if (!identity.company)
    {
      return;
    }
    const params: [string, string][] = [
      ['companyId', identity.company.id],
      ['documentId', documentId]
    ];

    const result = await this.authService.authGet<string[]>('/api/v2/documents/GetDocsNamesWithSameClassification', params);
    return result;
  }

  async searchDocumentsPage(pfilter: DocumentFilter): Promise<DocumentsResponse>
  {
    const filter = pfilter;
    pfilter.skipPreviewUrls = true;
    // pfilter.archived = false;
    const url = '/api/documents/queryDocuments';
    const result = await this.authService.authPost<DocumentsResponse>(url, filter);
    result.filter = filter;
    for (let i = 0 ; i < result.result.length ; i++)
    {
      const l = result.result[i];
      this.fixTimestampsAndAddUrl([l.latestVersion]);
    }
    return result;
  }

  async searchLibraryDocsForExtension(filter: DocumentFilter): Promise<PagedResponse<LibraryDocForExtensionDto>>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity?.company?.id;
    if (!companyId)
    {
      return;
    }

    filter.companyId = identity.company.id;
    const response = await this.authService.authGetObjectParam<PagedResponse<LibraryDocForExtensionDto>>(
      '/api/v2/documents/searchLibraryDocsForExtension',
      filter);

    return response;
  }

  async searchDocumentsForExtension(filter: DocumentFilter): Promise<PagedResponse<DocumentForExtensionDto>>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity?.company?.id;
    if (!companyId)
    {
      return;
    }
    filter.companyId = identity.company.id;
    filter.orderBy = 'Latest_Timestamp';
    const response = await this.authService.authPost<PagedResponse<DocumentForExtensionDto>>(
      '/api/v2/documents/searchDocumentsForExtension',
      filter);

    response.result?.forEach(r => r.latestVersion.uploadTimestampUtc = new Date(r.latestVersion.uploadTimestampUtc));
    return response;
  }

  async searchDocumentsForWeb(filter: DocumentFilter): Promise<PagedResponse<DocumentForWebDto>>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity?.company?.id;
    if (!companyId)
    {
      return;
    }
    filter.companyId = identity.company.id;
    const response = await this.authService.authPost<PagedResponse<DocumentForWebDto>>(
      '/api/v2/documents/searchDocumentsForWeb',
      filter);

    response.result?.forEach(r => r.latestVersion.uploadTimestampUtc = new Date(r.latestVersion.uploadTimestampUtc));
    return response;
  }

  async getDocumentVersionsForChecksums(companyId: string, checksums: string[]): Promise<DocumentVersion[]>
  {
    const query: ChecksumQuery =
    {
      companyId: companyId,
      checkSums: checksums
    };
    const data = await this.authService.authPost<DocumentVersion[]>(
      '/api/v2/documents/getDocVersionsByChecksums', query);
    return data;
  }

  async isAnyDocumentVersionForChecksum(checksum: string): Promise<boolean>
  {
    const identity = this.authService.getCurrentIdentity();
    const query: ChecksumQuery =
    {
      companyId: identity.company.id,
      checkSums: [checksum]
    };
    const docVersionExists = await this.authService.authPost<boolean>('/api/documents/isAnyDocumentVersionForChecksum', query);
    return docVersionExists;
  }

  async getDocumentVersionChecksum(versionId: string): Promise<string | null>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] =
    [
      ['companyId', identity.company.id],
      ['docVersionId', versionId]
    ];
    const checksum = await this.authService.authGet<string | null>('/api/documents/getDocumentVersionChecksum', params);
    return checksum;
  }

  async getDocumentVersionsName(versionIds: string[]): Promise<DocumentVersionName[]>
  {
    const ids = versionIds;
    const result = await this.authService.authPost<DocumentVersionName[]>('/api/documents/getDocumentVersionsName', ids);
    return result;
  }

  async postDocumentVersionMetadata(versionId: string, metadata: DocumentVersionMetadata): Promise<void>
  {
    const postData: DocumentVersionMetadataPost =
    {
      documentVersionId: versionId,
      metadata: metadata
    }
    await this.authService.authPost<void>('/api/documents/postDocumentVersionMetadata', postData);
  }

  private fixTimestampsAndAddUrl(versions: DocumentVersion[])
  {
    versions.forEach(v =>
    {
      v.timestampUtc = new Date(v.timestampUtc);
      if (v.approvedRejectedTimestampUtc)
      {
        v.approvedRejectedTimestampUtc = new Date(v.approvedRejectedTimestampUtc);
      }
      if (v.imageCompares && v.imageCompares.length > 0)
      {
        v.imageCompares.forEach(ic => ic.timestampUtc = new Date(ic.timestampUtc));
      }
    });
  }

  async addDocumentTag(tag: DocumentTag): Promise<DocumentTag>
  {
    const result = await this.authService.authPost<DocumentTag>('/api/documents/addDocumentTag', tag);
    return result;
  }

  async getExistingDocTagsNames(filterBy?: string): Promise<string[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] = [
      ['companyId', identity.company.id],
      ['filterBy', filterBy]
    ];
    const result = await this.authService.authGet<string[]>('/api/documents/getExistingDocTagsNames', params);
    return result;
  }

  async deleteDocumentTag(tagId: string): Promise<void>
  {
    const params: [string, string][] = [['tagId', tagId]];
    await this.authService.authGet('/api/documents/deleteDocumentTag', params);
  }

  async addDocumentRelation(relation: DocumentRelation): Promise<MoonDeskDocument>
  {
    const doc = await this.authService.authPost<MoonDeskDocument>('/api/documents/addDocumentRelation', relation);
    if (doc.latestVersion)
    {
      this.fixTimestampsAndAddUrl([doc.latestVersion]);
    }
    return doc;
  }

  async removeDocumentRelation(relation: DocumentRelation): Promise<void>
  {
    await this.authService.authPost<DocumentRelation>('/api/documents/removeDocumentRelation', relation);
  }

  async getDocumentRelations(documentId: string): Promise<MoonDeskDocument[]>
  {
    const param: [string, string][] = [
      ['documentId', documentId]
    ];
    const result = await this.authService.authGet<MoonDeskDocument[]>('/api/documents/getdocumentrelations', param);
    if (result && result.length > 0)
    {
      for (const doc of result)
      {
        this.fixTimestampsAndAddUrl([doc.latestVersion]);
      }
    }
    return result;
  }

  async getDocumentClassValueHistory(documentId: string): Promise<DocumentClassValuesChange[]>
  {
    const params: [string, string][] = [
      ['docId', documentId]
    ];
    const result = await this.authService.authGet<DocumentClassValuesChange[]>('/api/documents/getDocumentClassValueHistory', params);
    result?.forEach(cvChange => cvChange.timestampUtc = new Date(cvChange.timestampUtc));
    return result;
  }

  async getDocumentTagsHistory(documentId: string): Promise<DocumentTagChange[]>
  {
    const params: [string, string][] = [
      ['docId', documentId]
    ];
    const result = await this.authService.authGet<DocumentTagChange[]>('/api/documents/getDocumentTagsHistory', params);
    result?.forEach(cvChange => cvChange.timestampUtc = new Date(cvChange.timestampUtc));
    return result;
  }

  async getExportAsFile(documentVersion: DocumentVersion, exportFile: ExportFilesIdentifiers, filename?: string): Promise<File>
  {
    const url = await this.getFileUrl(documentVersion, exportFile);
    const blob = await this.http.get(url, {responseType: 'blob'}).toPromise();
    let type: string;
    switch (exportFile)
    {
      case ExportFilesIdentifiers.FullImagePng:
        type = 'image/png';
        break;
      case ExportFilesIdentifiers.Export_EditablePdf:
      case ExportFilesIdentifiers.Export_OutlinePdf:
      case ExportFilesIdentifiers.PreviewPdf:
        type = 'application/pdf';
        break;
      case ExportFilesIdentifiers.Export_Jpg:
        type = 'image/jpeg';
        break;
      default:
        type = '';
    }
    const ext = exportFile.split('.').pop();
    const file = new File([blob], filename ? `${filename}.${ext}` : exportFile,
      {
        type: type,
      });
    return file;
  }

  async webDownloadDocumentReport(filter: DocumentFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getDocumentReportUrl(filter);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Documents_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        window.URL.revokeObjectURL(url);
      }
    }
  }

  async getDocumentReportUrl(filter: DocumentFilter): Promise<string>
  {
    filter = this.prepareFilter(filter);

    const postData: DownloadDocReportPost =
    {
      documentFilter: filter,
      timezoneOffset: new Date().getTimezoneOffset()
    };
    const data = await this.authService.authPost<Blob>('/api/documents/downloaddocumentreport', postData, true);
    return window.URL.createObjectURL(data);
  }

  async requestSendDocument(request: RequestSendDocuments): Promise <SentDocument>
  {
    const response = await this.authService.authPost<SentDocument>('/api/documents/requestSendDocuments', request);
    return response;
  }

  async recreatePreviews(versionId: string)
  {
    const param: [string, string][] = [
      ['versionId', versionId]
    ];
    const response = await this.authService.authGet<boolean>('/api/documents/recreatePreviews', param);
    return response;
  }

  /**
   * Extract PDF metadata info (fonts, colors, transparency/overprint) from xml header in the file
   */
  async getPdfMetadataFromFile(file: File): Promise<DocumentVersionMetadata>
  {
    const fileDataBuffer = await this.fileService.readFileAsync(file);
    const fileData = fileDataBuffer.toString();
    const result = this.getPdfMetadata(fileData);
    return result;
  }

  /**
   * Extract PDF metadata info (fonts, colors, transparency/overprint) from xml header in the file data
   */
  getPdfMetadata(fileData?: string): DocumentVersionMetadata
  {
    const xmlStartIndex = fileData.indexOf("<x:xmpmeta");
    const xmlEndIndex = fileData.indexOf("</x:xmpmeta>", xmlStartIndex);
    if (xmlStartIndex === -1 || xmlEndIndex === -1)
    {
      return new DocumentVersionMetadata();
    }

    const xmlEndIndexWithClosingTag = xmlEndIndex + "</x:xmpmeta>".length;

    const xml = fileData.substring(xmlStartIndex, xmlEndIndexWithClosingTag);

    const result = this.parsePdfXmlMeta(xml);
    return result;
  }

  private parsePdfXmlMeta(xmlString: string): DocumentVersionMetadata
  {
    const result = new DocumentVersionMetadata();

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    const xmpmeta = xmlDoc.getElementsByTagName('x:xmpmeta')[0];

    if (!xmpmeta)
    {
      console.error('Invalid XML format: Missing x:xmpmeta element.');
      return result;
    }

    const fontsData = Array.from(xmpmeta.getElementsByTagName('xmpTPg:Fonts')[0]?.getElementsByTagName('rdf:Bag')[0]?.getElementsByTagName('rdf:li') || []);
    if (fontsData)
    {
      for (const font of fontsData)
      {
        result.fonts.push({
          fontName: font.getElementsByTagName('stFnt:fontName')[0]?.textContent || '',
          fontFamily: font.getElementsByTagName('stFnt:fontFamily')[0]?.textContent || '',
          fontFace: font.getElementsByTagName('stFnt:fontFace')[0]?.textContent || '',
          fontFileName: font.getElementsByTagName('stFnt:fontFileName')[0]?.textContent || '',
        });
      }
    }

    const colorsData = Array.from(xmpmeta.getElementsByTagName('xmpTPg:PlateNames')[0]?.getElementsByTagName('rdf:Seq')[0]?.getElementsByTagName('rdf:li') || []);
    if (colorsData)
    {
      for (const color of colorsData)
      {
        result.colors.push(color.textContent || '');
      }
    }

    result.hasVisibleOverprint = xmpmeta.getElementsByTagName('xmpTPg:HasVisibleOverprint')[0]?.textContent?.toLowerCase() === 'true';
    result.hasVisibleTransparency = xmpmeta.getElementsByTagName('xmpTPg:HasVisibleTransparency')[0]?.textContent?.toLowerCase() === 'true';

    return result;
  }

  async updateDocumentName(doc: MoonDeskDocument): Promise<void>
  {
    await this.authService.authPost<void>('/api/documents/updateDocumentName', doc);
  }

  private prepareFilter(documentFilter: DocumentFilter): DocumentFilter
  {
    // to not interfere with form data, we create a copy
    let filterCopy: DocumentFilter = <any>{};
    filterCopy = Object.assign(filterCopy, documentFilter);
    // documentFilter = <any>filterCopy;

    // the only way to really be sure that a local timezone selection of dates
    // reaches the backend in utc AND covers the full minDate (starting at 00:00:00)
    // until the full maxDate (ending at 23:59:59) is to use these two if's:
    if (filterCopy.minLastUpdateDate)
    {
      const x = new Date(filterCopy.minLastUpdateDate);
      filterCopy.minLastUpdateDate = <any>`${x.getFullYear()}-${x.getMonth() + 1}-${x.getDate()}T00:00:00Z`;
    }
    if (filterCopy.maxLastUpdateDate)
    {
      const x = new Date(filterCopy.maxLastUpdateDate);
      filterCopy.maxLastUpdateDate = <any>`${x.getFullYear()}-${x.getMonth() + 1}-${x.getDate()}T23:59:59Z`;
    }
    const identity = this.authService.getCurrentIdentity();
    filterCopy.companyId = identity.company.id;
    return filterCopy;
  }
}
