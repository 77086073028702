import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'underscore';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { ClassValue } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    selector: 'lib-add-class-value-dialog',
    templateUrl: './add-class-value-dialog.component.html',
    styleUrls: ['./add-class-value-dialog.component.scss'],
    standalone: false
})
export class AddClassValueDialogComponent implements OnInit {

  classValueController: UntypedFormGroup;
  name: AbstractControl;
  code: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AddClassValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder, private feedbackService: FeedbackService)
    {
      const currentClassValue: ClassValue = data.currentClassValue;
      let existingClassValues: ClassValue[] = data.existingClassValues;
      const parentClassValue: ClassValue = currentClassValue ? currentClassValue.parent : data.parentClassValue;

      if (parentClassValue)
      {
        existingClassValues = _.filter(existingClassValues, cv => cv.parentId === parentClassValue.id);
      }
      if (currentClassValue)
      {
        existingClassValues = _.filter(existingClassValues, cv => cv.id !== currentClassValue.id);
      }
      let name = '';
      let code = '';
      if (currentClassValue)
      {
        name = currentClassValue.name;
        code = currentClassValue.code;
      }
      else if (data.name)
      {
        name = data.name;
        // code = name.substring(0, 3);
      }
      this.classValueController = this.fb.group({
        name: [name, [Validators.required, this.duplicateValueValidator(existingClassValues)]],
        code: [code, [this.duplicateCodeValidator(existingClassValues)]]
      });
      this.name = this.classValueController.get('name');
      this.code = this.classValueController.get('code');
    }

  ngOnInit() {
  }

  duplicateValueValidator(existingClassValues: ClassValue[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = _.any(existingClassValues, cv => cv.name && control.value && cv.name.toLowerCase() === control.value.toLowerCase());
      return forbidden ? {'duplicateValue': {value: control.value}} : null;
    };
  }

  duplicateCodeValidator(existingClassValues: ClassValue[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = _.any(existingClassValues, cv => cv.code && control.value && cv.code.toLowerCase() === control.value.toLowerCase());
      return forbidden ? {'duplicateCode': {value: control.value}} : null;
    };
  }

  canSave(): boolean
  {
    return this.code.valid && this.name.valid;
  }

  close()
  {
    this.dialogRef.close();
  }

  save()
  {
    if (this.canSave())
    {
      this.dialogRef.close([this.name.value, this.code.value]);
    }
    else
    {
      this.classValueController.markAllAsTouched();
      if (this.name.hasError('duplicateValue') || this.code.hasError('duplicateCode'))
      {
        this.feedbackService.notifyMessage('Duplicate class value (name or code)');
      }
      else if (this.name.hasError('required'))
      {
        this.feedbackService.notifyMessage('Class value name is required');
      }
      else
      {
        // Should not happend
        this.feedbackService.notifyMessage('Invalid class value (name or code)');
      }
    }
  }

}
