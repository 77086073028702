import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {  MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import {
  MoonDeskDocument,
  EventHubService,
  DocumentService,
  PermissionsService,
  AuthService,
  UserAction,
  DocumentForExtensionDto
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { ClassSelectorDialogComponent } from '../../class-selector-dialog/class-selector-dialog.component';
import { PictureViewerComponent } from '../../picture-viewer/picture-viewer.component';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { FeedbackService } from 'src/app/services/feedback.service';


@Component({
    selector: 'document-card',
    templateUrl: './document-card.component.html',
    styleUrls: ['./document-card.component.scss'],
    standalone: false
})
export class DocumentCardComponent implements OnInit
{
  pictureViewerDialogRef: MatDialogRef<PictureViewerComponent>;

  @Input() document: DocumentForExtensionDto;
  @Input() disabled: boolean;
  @Input() simpleView: boolean;
  @Input() nopreview: boolean;
  @Output() selectionChanged: EventEmitter<DocumentForExtensionDto> = new EventEmitter<DocumentForExtensionDto>();
  @Output() documentChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleting: EventEmitter<boolean> = new EventEmitter<boolean>();

  downloading: boolean;
  busy: boolean;
  canReclassify: boolean = false;


  constructor(private dialog: MatDialog,
              private eventHubService: EventHubService,
              private documentService: DocumentService,
              private feedbackService: FeedbackService,
              private permissionsService: PermissionsService,
              private authService: AuthService,
              private clipboard: Clipboard)
  {
    this.eventHubService.goToEditDocuments.subscribe(() => { if (this.pictureViewerDialogRef) { this.pictureViewerDialogRef.close(); } });
  }

  async ngOnInit()
  {
    this.canReclassify = this.permissionsService.currentUserHasPermissionTo(UserAction.reclassifyDocuments);
    this.document.latestVersionString = this.getLatestVersionString();
  }

  checkedChanged(){
    this.selectionChanged.emit(this.document);
  }

  showBig()
  {
    this.pictureViewerDialogRef = this.dialog.open(PictureViewerComponent,
    {
      width: '90%',
      height: '90%',
      minWidth: '260px',
      data:
      {
        documentId: this.document.documentId,
        showDownload: true,
        showEdit: true
      }
    });
  }

  getTagsString(): string
  {
    if (this.document.latestVersion.tagsValues.length === 0)
    {
      return '--';
    }
    return this.document.latestVersion.tagsValues.join(', ');
  }

  async edit()
  {
    if (this.disabled)
    {
      return;
    }

    await this.checkAndLoadFullDocument();
    if (!this.document.fullDocument)
    {
      return;
    }
    this.document.fullDocument.editingVersion = this.document.fullDocument.latestVersion;
    this.eventHubService.goToWorkEdit.emit([this.document.fullDocument]);
  }

  async deleteDocument()
  {
    if (this.disabled)
    {
      return;
    }
    try
    {
      if (!await this.ask('Do you really want to delete this document?'))
      {
        return;
      }
      this.deleting.emit(true);
      await this.documentService.removeDocument(this.document.documentId);
      this.feedbackService.notifyMessage('Document deleted');
      this.documentChanged.emit();
    }
    catch (err)
    {
      const msg = err.error ? err.error : 'Document could not be deleted';
      this.feedbackService.notifyError(msg, err);
    }
    finally
    {
      this.deleting.emit(false);
    }
  }

  private ask(question: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result == 'yes'));
    });
  }

  private getLatestVersionString(): string
  {
    const version = this.document.latestVersion;
    const timeString = formatDate(version.uploadTimestampUtc, `dd/MM/yyyy - HH:mm:ss`, 'en-US');
    const t = `No.${version.versionNumber} from ${version.creatorUsername} at ${timeString}`;
    return t;
  }

  getStatusTooltip()
  {
    switch (this.document.latestVersion.versionStatus)
    {
      case 'Draft':
        return 'Document is a draft';
      case 'Approved':
        if (this.document.latestVersion.approvedRejectedByUsername)
        {
          return `Document is approved by ${this.document.latestVersion.approvedRejectedByUsername}`;
        }
        else
        {
          return 'Document is approved';
        }
    }
  }

  getFieldValues()
  {
    const valuesOrdered = _.sortBy(this.document.latestVersion.fieldValues, fv => fv.fieldType.name.toLowerCase());
    return valuesOrdered.slice(0, 3);
  }

  async openDownloadDialog()
  {
    if (this.disabled)
    {
      return;
    }

    await this.checkAndLoadFullDocument();
    if (!this.document.fullDocument)
    {
      return;
    }
    this.document.fullDocument.editingVersion = this.document.fullDocument.latestVersion;
    this.eventHubService.openDownloadDialog.emit([this.document.fullDocument]);
  }

  async download(exportFile?: string)
  {
    if (this.downloading)
    {
      return;
    }
    try
    {
      this.downloading = true;
      const url = await this.documentService.getSpecificFileUrl(this.document.latestVersion.versionId, exportFile);
      this.authService.webDownload(url);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error downloading the document', err);
    }
    this.downloading = false;
  }

  copyMoonNumber()
  {
    this.clipboard.copy(this.document.moonNumber);
    this.feedbackService.notifyMessage('MoonNumber copied to clipboard');
  }

  /**
   * Load full document if not yet downloaded
   */
  private async checkAndLoadFullDocument()
  {
    if (this.document.fullDocument)
    {
      return;
    }

    try
    {
      this.busy = true;
      this.document.fullDocument = await this.documentService.getDocument(this.document.documentId);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error loading document', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  private showClassSelectorPopup(document: MoonDeskDocument): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(ClassSelectorDialogComponent, {
        width: 'auto',
        minWidth: 'auto',
        data: {
          document: document
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe( result => resolve(result));
    });
  }

  async changeInfos()
  {
    await this.checkAndLoadFullDocument();
    if (!this.document.fullDocument)
    {
      return;
    }

    if (await this.showClassSelectorPopup(this.document.fullDocument))
    {
      try
      {
        const response = await this.documentService.searchDocumentsForExtension({documentId: this.document.documentId, page: 1});
        if (response.result?.length > 0)
        {
          this.document = response.result[0];
          this.document.latestVersionString = this.getLatestVersionString();
        }
      }
      catch (err)
      {
        this.feedbackService.notifyError('Error updating document info', err);
      }
    }
  }

}
