import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  Progress,
  DocumentFilter,
  AuthService,
  LibDocTypesNames,
  TranslationService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { LibraryCacheService, LibraryElement } from '../../services/library-cache.service';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ProgressControlComponent } from '../_shared/progress-control/progress-control.component';
import {  MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CurrentDocumentChangedEvent, CurrentDocumentService } from '../work/current-document-manager/current-document.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(':enter', style({ opacity: 0, transform: 'translateY(-100%)' }), { optional: true }),
                query(':enter', stagger('100ms', [
                    style({ transform: 'translateY(100%)' }),
                    animate('0.65s ease')
                ]), { optional: true }),
            ])
        ])
    ],
    standalone: false
})

export class LibraryComponent implements OnInit, OnDestroy {

  currentPage: number = 1;
  totalPages: number = 0;
  totalResults: number;
  lastDocumentChangedId: string;

  libraryElements: LibraryElement[] = [];
  filterText = new UntypedFormControl();
  searchText: string;
  libraryElementType: 'all' | 'image' | 'font' | 'currentDoc' | 'archived' = 'all';

  uploading: boolean;

  viewMode: 'list' | 'grid' = 'list';

  busy: boolean;

  progressDialogRef: MatDialogRef<ProgressControlComponent>;

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  private subscriptions: Subscription[] = [];

  constructor(
    private feedbackService: FeedbackService,
    private libraryCacheService: LibraryCacheService,
    private authService: AuthService,
    private dialog: MatDialog,
    private currentDocService: CurrentDocumentService,
    private navigationService: NavigationService,
    private translationService: TranslationService)
  {
  }

  ngOnInit()
  {
    this.subscriptions.push(this.authService.identityChanged.subscribe(() => this.resetFilter()));
    this.subscriptions.push(this.currentDocService.documentChange.subscribe((event) => this.onCurrentDocumentChange(event)));
    this.subscriptions.push(this.navigationService.navToLibrary.subscribe(() => this.searchLibrary()));
  }

  ngOnDestroy(): void
  {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private onCurrentDocumentChange(docChangeEvent: CurrentDocumentChangedEvent)
  {
    const document = docChangeEvent.editDocument ?? docChangeEvent.importDocument;
    if (!document)
    {
      this.lastDocumentChangedId = undefined;
      this.libraryElementType = 'all';
      if (this.navigationService.currentPage === 'library')
      {
        this.searchLibrary();
      }
    }
    else if (document.id !== this.lastDocumentChangedId)
    {
      this.libraryElementType = 'currentDoc';
      this.libraryElements = [];
      this.lastDocumentChangedId = document.id;
      if (this.navigationService.currentPage === 'library')
      {
        this.searchLibrary();
      }
    }
  }

  resetFilter()
  {
    this.filterText.reset();
    // this.searchTags = [];
    this.searchText = '';
    this.libraryElementType = 'all';
    this.searchLibrary();
  }

  async searchLibrary()
  {
    await this.authService.waitForInit();
    this.currentPage = 1;
    this.libraryElements = [];
    this.libraryElements = await this.getLibraryElements();
  }

  async loadMoreLibraryElements()
  {
    if (this.busy)
    {
      return;
    }
    if (this.currentPage < this.totalPages)
    {
      this.currentPage++;
    }
    else
    {
      return;
    }

    const newLibElements = await this.getLibraryElements();
    this.libraryElements = this.libraryElements.concat(newLibElements);
  }

  private async getLibraryElements(): Promise<LibraryElement[]>
  {
    try
    {
      this.busy = true;
      const identity = this.authService.getCurrentIdentity();
      if (!identity)
      {
        return [];
      }

      const docTypesIds: string[] = [];
      let includeArchived: boolean;

      if (this.libraryElementType === 'font')
      {
        const fontDocType = _.find(identity?.company.documentTypes, dt => dt.isLibraryType && dt.name === LibDocTypesNames.fontTypeName);
        if (!fontDocType)
        {
          throw Error('Missing Font docType');
        }
        docTypesIds.push(fontDocType.id);
      }

      if (this.libraryElementType === 'image')
      {
        const imgDocType = _.find(identity?.company.documentTypes, dt => dt.isLibraryType && dt.name === LibDocTypesNames.imageTypeName);
        if (!imgDocType)
        {
          throw Error('Missing Image docType');
        }
        docTypesIds.push(imgDocType.id);
      }

      if (this.libraryElementType === 'archived')
      {
        includeArchived = true;
      }

      if (this.libraryElementType === 'currentDoc')
      {
        const currentDoc = this.currentDocService.editDocument;
        if (!currentDoc)
        {
          this.totalPages = 1;
          this.totalResults = 0;
          return [];
        }
        const result = await this.libraryCacheService.getLibraryElementsByIds(currentDoc.latestVersion.childVersionIds);
        this.totalPages = 1;
        this.totalResults = result.length;
        return result;
      }
      else
      {
        const filter: DocumentFilter =
        {
          classSelections: [],
          docTypeIds: docTypesIds,
          library: true,
          companyId: identity?.company.id,
          skipPreviewUrls: false,
          page: this.currentPage,
          searchText: this.searchText,
          archived: includeArchived,
        };

        const response = await this.libraryCacheService.searchLibraryElements(filter);
        this.totalPages = response.pageCount;
        this.totalResults = response.totalResult;
        return response.result;
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting library elements', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  addSearchTag()
  {
    const value = this.filterText.value;
    if (!value)
    {
      return;
    }
    this.searchText = value;
    this.filterText.reset();
    this.searchLibrary();
  }

  removeSearchTag()
  {
    this.searchText = '';
    this.searchLibrary();
  }

  async addDocument(files: any[])
  {
    try
    {
      this.uploading = true;
      if (!files || files.length === 0)
      {
        this.feedbackService.notifyMessage('No file selected');
        return;
      }
      const progress = {
        cancelAction: () => {},
        loadedKB: 0,
        loadedMB: 0,
        percentage: 0,
        speedKBps: 0,
        speedMBps: 0,
        totalKB: 0,
        totalMB: 0
      };
      this.openProgressDialog(progress);
      await this.libraryCacheService.createLibraryElement(files[0].path, (p) =>
      {
        progress.cancelAction = p.cancelAction;
        progress.loadedKB = p.loadedKB;
        progress.loadedMB = p.loadedMB;
        progress.percentage = p.percentage;
        progress.speedKBps = p.speedKBps;
        progress.speedMBps = p.speedMBps;
        progress.totalKB = p.totalKB;
        progress.totalMB = p.totalMB;
      });
      await this.feedbackService.notifyMessage('The file has been uploaded successfully');
    }
    catch (err)
    {
      const msg = err && err.message ? `Error adding library element: ${err.message}` : 'Error adding library element';
      if (err.message)
      {
        await this.feedbackService.notifyMessage(msg);
      }
      else
      {
        await this.feedbackService.notifyError(msg, err);
      }
    }
    finally
    {
      this.progressDialogRef.close();
      this.uploading = false;
      this.fileInput.nativeElement.value = '';
      this.resetFilter();
    }
  }

  private openProgressDialog (progress: Progress)
  {
    this.progressDialogRef = this.dialog.open(ProgressControlComponent, {
      width: '400px',
      minWidth: '260px',
      data: progress,
      disableClose: true
    });
  }

  addFiles()
  {
    const identity = this.authService.getCurrentIdentity();
    if (identity.company.isStorageUsageLimitReached)
    {
      this.feedbackService.notifyMessage(
        this.translationService.getTranslation('lid.srv.StorageUsageLimitReached')
      );
      return;
    }
    this.fileInput.nativeElement.click();
  }
}
